import imageCompression from 'browser-image-compression';
import { FILE_SIZE, SUPPORTED_FORMATS, TOKEN } from '~/config/constants';

export function isBackspace(event) {
  return event.nativeEvent.inputType === 'deleteContentBackward';
}

export const checkIfFileAreCorrectType = (file) => {
  let valid = true;

  const extension = file.name.split('.').pop().toLowerCase();

  if (!SUPPORTED_FORMATS.includes(`.${extension}`)) {
    valid = false;
  }

  return valid;
};

export function checkIfFileAreTooBig(file) {
  let valid = true;

  if (file.size >= FILE_SIZE) {
    valid = false;
  }

  return valid;
}

export const toBytes = (value) => {
  return value << 20;
};

export const compressedFile = async (file) => {
  const array = ['image/jpeg', 'image/png', 'image/jpg'];

  const options = {
    maxSizeMB: 4,
    useWebWorker: true,
  };

  if (array.indexOf(file.type) !== -1) {
    const imageCompressed = await imageCompression(file, options);

    return imageCompressed;
  }
  if (file.size < toBytes(4)) {
    return file;
  }
};

export function getToken() {
  const token = localStorage.getItem(TOKEN);
  return token;
}

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  return date.toLocaleString('pt-BR', { timeZone: 'UTC' });
};

export const formatDateToSefaz = (date) => {
  const dateSplit = date.split('-');
  const operationDate = `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;

  return operationDate;
};

export const formatJson = (json) => {
  try {
    const formattedJson = JSON.stringify(JSON.parse(json), null, 4);

    return formattedJson;
  } catch (error) {
    return 'Não foi possível renderizar este json, arquivo muito extenso';
  }
};

export const getToday = () => {
  const today = new Date();
  const date = today.toISOString().substr(0, 10);

  return date;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const decimalSeparator = (number) =>
  number
    .toString()
    .split(/(?=(?:...)*$)/)
    .join('.');

export const formatNumberSeal = (str = '') => {
  const letters = str.substr(0, 4);
  const numbers = decimalSeparator(str.substr(4, str.length));
  return `${letters}-${numbers}`;
};

export const formatQuantity = quantity => {
  if (!quantity) return 0;

  const formattedQuantity = parseFloat(quantity);

  return formattedQuantity.toLocaleString('pt-BR', { timeZone: 'UTC' });
};

export const formatDay = (timestamp) => {
  const date = timestamp.split('T');
  const day = date[0].split('-');

  return `${day[2]}/${day[1]}/${day[0]}`;
};

export const currentYear = () => {
  return new Date().getFullYear();
}
