import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, ContainerButton, ContainerInput, Label } from './styles';
import Select from '~/components/Select';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { fetchPackages, fetchBrands } from '~/store/modules/companies/actions';
import { SUPPORTED_FORMATS, ERROR_FILE_SIZE, ERROR_FILE_TYPE } from '~/config/constants';
import image from '~/assets/photo.png';
import { checkIfFileAreCorrectType, checkIfFileAreTooBig, compressedFile } from "~/utils";
import { newProductFormSchema } from '~/utils/validation';
import { numberDecimalMask } from '~/utils/mask';
import { createProduct } from "~/store/modules/products/actions";

function NewProduct() {
  
  const dispatch = useDispatch();
  const [packagesType, setPackagesType] = useState([]);
  const [brandsCompany, setBrandsCompany] = useState([]);
  const [idPackage, setIdPackage] = useState({});
  const [idBrand, setIdBrand] = useState({});
  const { packages, brands } = useSelector(state => state.companies);
  const { statusCreate } = useSelector(state => state.products);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(newProductFormSchema)
  });

  useEffect(() => {
    dispatch(fetchPackages());
    dispatch(fetchBrands());
  }, []);

  useEffect(() => {
    setPackagesType(packages?.payload)
  }, [packages]);

  useEffect(() => {
    setBrandsCompany(brands?.payload);
  }, [brands]);
  
  const [containerImage, setContainerImage] = useState([{imagePreview: image}]);
  const [labelImage, setLabelImage] = useState([{imagePreview: image}]);

  const handleChangeImage = async (event, imagePreview, setState) =>{
    const list = [...imagePreview];
    const reader = new FileReader();
    const file =  event.target.files[0];
    const fileCompressed = await compressedFile(file);
    const fileAreTooBig = checkIfFileAreTooBig(fileCompressed);
    const fileAreCorrectType = checkIfFileAreCorrectType(fileCompressed);

    if(!fileAreTooBig){
      list[0].error = ERROR_FILE_SIZE;
    }

    if(!fileAreCorrectType){
      list[0].error = ERROR_FILE_TYPE;
    }

    if(fileAreCorrectType && fileAreTooBig){
      if (fileCompressed) reader.readAsDataURL(fileCompressed);
        const result = await new Promise((resolve) => {
          reader.onload = function() {
            resolve(reader.result);
          }
      })

      list[0].imagePreview = result;
      list[0].error = '';
    }

    setState(list);
  }

  const handlePackages = async packageId => {
    setIdPackage(packageId);
  }

  const handleBrands = async brandId => {
    setIdBrand(brandId);
  }
  
  const onSubmit = (data) => {
    data.containerImage = containerImage[0].imagePreview;
    data.labelImage = labelImage[0].imagePreview;
    data.containerVolume = data.containerVolume.replace(/[^\d]+/g,'');
    dispatch(createProduct(data));
  };

  return (
    <>
      <Container>
        <h2>Cadastro de produto</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input.Base 
            label="Nome do produto"
            name="name"
            type="text"
            ref={register}
            errors={errors.name}
            maxLength={50}
          />
          <Select.Base
            label="Marca"
            name="brand"
            type="text"
            options={brandsCompany}
            data={idBrand}
            ref={register} 
            onChange={event => handleBrands(event.target.value)}
            defaultValue="Selecione"
            errors={errors.brand}
          />
          <Input.Base 
            label="Código EAN-13"
            name="ean13Code"
            type="text"
            ref={register}
            errors={errors.ean13Code}
            maxLength={13}
          />
          <Input.Base 
            label="Código NCM"
            name="ncmCode"
            type="text"
            ref={register}
            errors={errors.ncmCode}
            maxLength={8}
          />
          <ContainerInput>
            <Input.Base 
              label="Volume do recipiente (ml)"
              name="containerVolume"
              type="text"
              ref={register}
              errors={errors.containerVolume}
              onChange={(event) => { event.target.value = numberDecimalMask(event)}}
              maxLength="12"
            />
            <Select.Base
              label="Tipo de embalagem"
              name="packageType"
              type="text"
              data={idPackage}
              defaultValue="Selecione"
              ref={register}
              errors={errors.packageType}
              options={packagesType}
              onChange={event => handlePackages(event.target.value)}
            />
          
          </ContainerInput>
          <ContainerInput>
            <div className='image'>
              <h3>Foto de um recipiente</h3>
              <label htmlFor="containerImage">
                <img
                  src={containerImage[0].imagePreview}
                  alt="Selecione uma imagem"
                  className="upload-icon"
                />
                <input
                  name="containerImage"
                  id="containerImage"
                  type="file"
                  accept={SUPPORTED_FORMATS}
                  ref={register}
                  onChange={event => handleChangeImage(event, containerImage, setContainerImage)}
                />
              </label>
            </div>
            <div className='image'>
              <h3>Foto de um rótulo</h3>
              <label htmlFor="labelImage">
                <img
                  src={labelImage[0].imagePreview}
                  alt="Selecione uma imagem"
                  className="upload-icon"
                /> 
                <input
                  name="labelImage"
                  id="labelImage"
                  type="file"
                  accept={SUPPORTED_FORMATS}
                  ref={register}
                  onChange={event => handleChangeImage(event, labelImage, setLabelImage)}
                />
              </label>
            </div>
          </ContainerInput>
          <Label>
            Observação
          </Label>
          <textarea
            name="observation"
            ref={register}
            errors={errors.observation}
            maxLength={200}
            placeholder="Preencha com informações necessárias a serem consideradas pela SEFAZ-SP (Ex.: descrição de mais de um tipo de rótulo ou cor de
                recipiente, etc)"
          />
          <ContainerButton>
            <Button.Rounded type="submit" disabled={statusCreate} loading> 
              Confirmar
            </Button.Rounded>
          </ContainerButton>
        </form>
      </Container>
    </>
  );
}

export default NewProduct;
