import * as yup from 'yup';
import {
  ERROR_EMAIL,
  ERROR_PASSWORD_NOT_MATCH,
  ERROR_QUANTITY_ZERO, ERROR_REQUIRED,
  ERROR_STRING_LENGTH,
  PATTERN_CNPJ,
  PATTERN_EMAIL,
  PATTERN_PHONE,
  PATTERN_QUANTITY, PATTERN_ZIP_CODE
} from '~/config/constants';
import { errorInvalidPattern } from './errors';

function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}

yup.addMethod(yup.string, 'equalTo', equalTo);

const email = yup
  .string()
  .trim()
  .matches(PATTERN_EMAIL, ERROR_EMAIL)
  .required(ERROR_REQUIRED);

const password = yup
  .string()
  .trim()
  .min(8, 'A senha precisa ter no mínimo 8 caracteres.')
  .required(ERROR_REQUIRED);

const repeatPassword = yup
  .string()
  .equalTo(yup.ref('password'), ERROR_PASSWORD_NOT_MATCH)
  .required(ERROR_REQUIRED);

const cnpj = yup
  .string()
  .trim()
  .matches(PATTERN_CNPJ, errorInvalidPattern('58.181.650/0001-60'))
  .required(ERROR_REQUIRED);

const zipCode = yup
  .string()
  .trim()
  .matches(PATTERN_ZIP_CODE, errorInvalidPattern('06474-521'))
  .required(ERROR_REQUIRED);

const name = yup
  .string()
  .trim()
  .required(ERROR_REQUIRED)
  .min(3, ERROR_STRING_LENGTH);

const select = yup.string().notOneOf(['0'], ERROR_REQUIRED);

const neighborhood = yup
.string()
.trim()
.required(ERROR_REQUIRED);

const street = yup
  .string()
  .trim()
  .required(ERROR_REQUIRED);

const number = yup
  .string()
  .trim()
  .required(ERROR_REQUIRED);

const complement = yup
  .string()
  .trim();

const phone = yup
  .string()
  .trim()
  .matches(PATTERN_PHONE, errorInvalidPattern('(11) 4157-5145 | (11) 96521-9852'))
  .required(ERROR_REQUIRED);

const quantity = yup
  .string()
  .trim()
  .matches(PATTERN_QUANTITY, ERROR_QUANTITY_ZERO)
  .required(ERROR_REQUIRED);

  export const year = yup
  .string()
  .matches(/(\d){4}/, 'O ano é inválido')
  .max(4, 'O ano não pode conter mais que 4 caracteres')
  .required(ERROR_REQUIRED);

const checkbox = yup.bool().oneOf([true], ERROR_REQUIRED);

export const loginFormSchema = yup.object().shape({
  email,
  password,
});

export const registrationFormSchema = yup.object().shape({
  cnpj,
  name,
  fantasyName: name,
  address: yup.object().shape({ 
    zipCode
  }) 
});

export const registrationAddressFormSchema = yup.object().shape({
  cnpj,
  name,
  fantasyName: name,
  address: yup.object().shape({  
    neighborhood,
    state: select,
    city: select,
    street,
    number,
    complement,
    zipCode,
  }) 
});

export const registrationRegisterFormSchema = yup.object().shape({
  registerType: select,
  registerNumber: number,
  registerYear: year,
  sanitaryLicenseNumber: number,
  sanitaryLicenseYear: year,
  environmentalLicenseNumber: number,
  environmentalLicenseYear: year
});

export const registrationContactFormSchema = yup.object().shape({
  contact: yup.object().shape({  
      contactType: select,
      name,
      appName: yup.string().notRequired().when('contactType', {
        is: '1',
        then: yup.string().min(3, 'O nome deve conter no mínimo 3 dígitos.').required(ERROR_REQUIRED)
      }),
      contact: yup.string().when('contactType', {
        is: '3',
        then: email,
        otherwise: phone,
      }),
    }) 
});

export const registrationBrandFormSchema = yup.object().shape({
  brands: yup.array().of(
    yup.object().shape({
      name,
      waterType: select,
    })
  )  
});

export const newOrderFormSchema = yup.object().shape({
  product: select,
  quantity,
});

export const newProductFormSchema = yup.object().shape({
  brand: yup.string().notOneOf(['0'], ERROR_REQUIRED),
  ean13Code: yup.string().min(13, 'O código deve conter 13 dígitos.').required(ERROR_REQUIRED),
  ncmCode: yup.string().min(8, 'O código deve conter 8 dígitos.').required(ERROR_REQUIRED),
  containerVolume: yup.string().required(ERROR_REQUIRED),
  packageType: yup.string().notOneOf(['0'], ERROR_REQUIRED),
  name: yup.string().required(ERROR_REQUIRED),
});


export const registrationLoginFormSchema = yup.object().shape({
  user: yup.object().shape({ 
      name,
      email,
      password,
      repeatPassword,
      term: yup.object().shape({  
        first: checkbox,
        second: checkbox,
        third: checkbox,
        fourth: checkbox
      })
    })
});

export const registrationLoginCertificateFormSchema = yup.object().shape({
  user: yup.object().shape({ 
      name,
      term: yup.object().shape({  
        first: checkbox,
        second: checkbox,
        third: checkbox,
        fourth: checkbox
      })
    })
});

export const informationSealFormSchema = yup.object().shape({
  operationDate: yup.string().required(ERROR_REQUIRED)
});

export const graphicMisplacementFormSchema = yup.object().shape({
  operationDate: yup.string().required(ERROR_REQUIRED),
  unitarySeals: yup.array().of(
    yup.object().shape({
      sealCode: name,
      scrapingCode: name,
    })
  ) 
});

export const fillingUseUnitarySealsFormSchema = yup.object().shape({
  operationDate: yup.string().required(ERROR_REQUIRED),
  unitarySeals: yup.array().of(
    yup.object().shape({
      sealCode: name,
    })
  ) 
});

export const fillingUseRangeFormSchema = yup.object().shape({
  operationDate: yup.string().required(ERROR_REQUIRED),
  sequentialSeals:yup.array().of(
    yup.object().shape({  
    side: name,
    inferiorLimit: number,
    upperLimit: number
  })
  ) 
});

export const fillingUseFormSchema = yup.object().shape({
  operationDate: yup.string().required(ERROR_REQUIRED),
  unitarySeals: yup.array().of(
    yup.object().shape({
      sealCode: name,
    })
  ),
  sequentialSeals:yup.array().of(
    yup.object().shape({  
    inferiorLimit: number,
    upperLimit: number
  })
  ) 
});

export const queryFormSchema = yup.object().shape({
  sealCode: yup
  .string()
  .matches(/([A-Z]{4})-([\d]{3}.)([\d]{3}.)([\d]{3})/gm, errorInvalidPattern('PAAA-000.000.004')),
 scrapingCode: yup
  .string()
  .notRequired().test('scrapingCode', errorInvalidPattern('MLJ64007'), function(value) {
    if (value) {
      const schema = yup.string().matches(/([A-Z]{3})([\d]{5})/gm);
      return schema.isValidSync(value);
    }
    return true;
  }),
});
