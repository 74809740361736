import { companies } from '~/services/api';
import { requestError } from '~/utils/errors';
import { requestData } from '..';
import { showModal } from '../modal/actions';

const requestCompaniesData = requestData('@companies');

export const fetchCompanyByProtocol = () => async dispatch => {
  requestCompaniesData(
    dispatch,
    () => companies.selectDetailsCadastralSituation(),
    payload => ({ type: '@company/FETCH_COMPANY_BY_PROTOCOL', payload })
  );
};

export const fetchPackages = () => async dispatch => {
  try {
    const { data } = await companies.selectTypePackage();
    dispatch({ type: '@company/FETCH_PACKAGES', payload: data });
  } catch (error) {
    requestError(error);
  }
};

export const fetchBrands = () => async dispatch => {
  try {
    const { data } = await companies.selectBrandCompany();
    dispatch({ type: '@company/FETCH_BRANDS', payload: data });
  } catch (error) {
    requestError(error);
  }
};

export const fetchFilling = () => async dispatch => {
  try {
    const { data } = await companies.selectFillingDetails();
    dispatch({ type: '@company/FETCH_FILLING_DETAILS', payload: data });
  } catch (error) {
    requestError(error);
  }
};

export const fetchAddresses = () => async dispatch => {
  try {
    const { data } = await companies.selectAddressCompany();
    dispatch({ type: '@company/FETCH_ADDRESSES', payload: data });
  } catch (error) {
    requestError(error);
  }
};

export const fetchContacts = () => async dispatch => {
  try {
    const { data } = await companies.selectContactsCompany();
    dispatch({ type: '@company/FETCH_CONTACTS', payload: data });
  } catch (error) {
    requestError(error);
  }
};

export const fetchRegistriesTypes = () => async dispatch => {
  requestCompaniesData(
    dispatch,
    () => companies.selectRegistriesTypes(),
    payload => ({ type: '@companies/FETCH_REGISTRIES_TYPES', payload })
  );
};

export const fetchCnpj = (cnpj) => async dispatch => {
  try {
    await companies.selectCnpj(cnpj);

    dispatch({ type: '@companies/FETCH_CNPJ', payload: true });
  } catch (error) {
    if (error.response.status === 404) {
      dispatch({ type: '@companies/FETCH_CNPJ', payload: false });
    }else{
      requestError(error);
    }
  }
};

export const sendFormData = (newFormData) => async dispatch => {
    dispatch({ type: '@companies/SEND_FORM_DATA', newFormData })
};

export const clearFormData = () => async dispatch => {
    dispatch({ type: '@companies/CLEAR_FORM_DATA' })
};

export const createCompany = company => async dispatch => {
  dispatch({ type: '@companies/FETCHING_DATA' })
  try {
    const { data } = await companies.insert(company);

    dispatch(showModal('open', 2));
    dispatch({ type: '@companies/SEND_PROTOCOL_NUMBER', payload: data.payload.protocolNumber })
  } catch (error) {
    requestError(error);
  }finally{
    dispatch({ type: '@companies/FETCHING_DATA_FINALLY' })
  }
};
