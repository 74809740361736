import 'react-toastify/dist/ReactToastify.min.css';
import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

  * {
    font-family: 'Poppins', sans-serif ;
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
  }

  html{
    font-size: 16px;
  }
`;

export default Global;

export const fontSizes = {
  tiny: '0.75rem',
  small: '0.875rem',
  default: '1.125rem',
  medium: '1.5rem',
  large: '3rem',
};

export const colors = {
  white: '#ffffff',
  bege: '#EEEEEE',
  gallery: '#EDEDED',
  lightGrey: '#9C9C9C',
  silver: '#CCCCCC',
  scarpaFlow: '#5E5E5F',
  shipGray: '#3D3D3E',
  mediumGrey: '#333333',
  darkGrey: '#1A1A1A',
  lightBlue: '#316F96',
  mediumBlue: '#1B5578',
  darkBlue: '#13374D',
  red: '#F44336',
  darkRed: '#8B1D1D',
  green: '#189D3E',
};

export const spacing = {
  shorter: '5px',
  short: '10px',
  base: '20px',
  smaller: '40px',
  tall: '80px',
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const lineHeight = {
  small: '23px',
  medium: '28px',
  large: '33px',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
