import { registries } from '~/services/api';
import { requestData } from '..';

const requestRegistriesData = requestData('@registries');

export const fetchWaterTypes = (registerType) => async dispatch => {
  requestRegistriesData(
    dispatch,
    () => registries.selectWatersTypes(registerType),
    payload => ({ type: '@registries/FETCH_WATER_TYPES', payload })
  );
};

