import produce from "immer";

const INITIAL_STATE = { 
  registriesTypes: [], 
  formData: {},
  companyByProtocol: {},
  packages: [],
  brands: [],
  addresses: [],
  contacts: [],
  filling: [],
  totalPages: 0,
  responseCnpj: false,
  protocolNumber: 0, 
  status: ''
};

const companies = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {

    switch (action.type) {
      case "@companies/FETCH_REGISTRIES_TYPES":
        draft.registriesTypes = action.payload;
        break;
      case "@companies/SEND_FORM_DATA":
        draft.formData = {...state.formData, ...action.newFormData};
        break;
        case "@company/FETCH_COMPANY_BY_PROTOCOL":
        draft.companyByProtocol = action.payload;
        break;
      case "@companies/FETCH_CNPJ":
        draft.responseCnpj = action.payload
        break;
      case "@companies/SEND_PROTOCOL_NUMBER":
        draft.protocolNumber = action.payload
        break;
      case "@companies/CLEAR_FORM_DATA":
        delete draft.formData;
        delete draft.responseCnpj;
        break;
      case "@companies/FETCHING_DATA":
        draft.status = 'fetching'
        break;
      case "@companies/FETCHING_DATA_FINALLY":
        draft.status = 'ok'
        break;
      case "@company/FETCH_PACKAGES":
        draft.packages = action.payload
        break;
      case "@company/FETCH_BRANDS":
        draft.brands = action.payload
        break; 
      case "@company/FETCH_ADDRESSES":
        draft.addresses = action.payload
        break;
      case "@company/FETCH_CONTACTS":
        draft.contacts = action.payload
        break;
      case "@company/FETCH_FILLING_DETAILS":
        draft.filling = action.payload
        break;
      default:
        return state;
    }
  });
};

export default companies;
