import styled from 'styled-components';
import { colors, spacing } from '~/style/global';

export const SelectField = styled.select`
  width: 100%;
  height: 40px;
  padding: ${spacing.short};
  box-sizing: border-box;
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;

  :disabled {
    opacity: 0.5;
  }
`;
