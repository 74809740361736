import produce from "immer";

const INITIAL_STATE = { contactsTypes: []};

const contacts = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {

    switch (action.type) {
      case "@contacts/FETCH_CONTACTS_TYPES":
        draft.contactsTypes = action.payload;
        break;
      default:
        return state;
    }
  });
};

export default contacts;
