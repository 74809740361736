import React, { useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import {
  FiMinimize, FiMinus,
  FiPlus, FiRotateCcw,
  FiRotateCw
} from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import Modal from '~/components/Modal';
import { showModal } from '~/store/modules/modal/actions';
import { Controllers, Image, ImageContainer, Portal } from './styles';

function ZoomImage({ title, img }) {
  const imageRef = useRef(null);
  const [angle, setAngle] = useState(0);

  const dispatch = useDispatch();

  function handleRotate(side = '') {
    const tag = imageRef?.current;
    const newAngle = side === 'LEFT' ? angle + 90 : angle - 90;

    setAngle(newAngle);
    tag.setAttribute('style', `transform: rotate(${newAngle}deg)`);
  }

  const handleClose = () => {
    dispatch(showModal('close', 8))
  };

  return (
    <Modal id={8}>
      <Portal>
        <CgClose onClick={handleClose} />
        <ImageContainer>
       
          <TransformWrapper
            defaultScale={1}
            options={{ limitToBounds: false }}
            wheel={{ disabled: true }}
            pinch={{ disabled: true }}
            defaultPositionX={0}
            defaultPositionY={0}
            positionY={0}
            positionX={0}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
              return (
                <>
                  <Controllers>
                    <button
                      type="button"
                      onClick={() => handleRotate('LEFT')}
                      title="Rotacionar em sentido horário"
                    >
                      <FiRotateCw />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleRotate('RIGHT')}
                      title="Rotacionar em sentido anti-horário"
                    >
                      <FiRotateCcw />
                    </button>

                    <button type="button" onClick={zoomIn} title="Adicionar zoom">
                      <FiPlus />
                    </button>

                    <button type="button" onClick={zoomOut} title="Remover zoom">
                      <FiMinus />
                    </button>

                    <button
                      type="button"
                      onClick={resetTransform}
                      title="Redefinir posição da imagem (remove o zoom e centraliza)"
                    >
                      <FiMinimize />
                    </button>

                  
                  </Controllers>

                  <h1>{title}</h1>

                  <TransformComponent>
                    <Image
                      src={img}
                      ref={imageRef}
                      alt={title}
                      {...rest}
                    />
                  </TransformComponent>
                </>
              );
            }}
          </TransformWrapper>        
        </ImageContainer>
      </Portal>
    </Modal>
  );
}

export default ZoomImage;
