export const PATTERN_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PATTERN_CNPJ = /[\d]{2}.[\d]{3}.[\d]{3}\/[\d]{4}-[\d]{2}/;
export const PATTERN_ZIP_CODE = /[\d]{5}-[\d]{3}/;
export const PATTERN_PHONE =  /\((\d){2}\)((\d){9}|(\d){8})/;
export const PATTERN_QUANTITY = /0*[1-9]\d{0,}/;

export const TOKEN = 'cb5059f80352e807395706f9e9cd3d2cd449728e';

export const SUPPORTED_FORMATS = [
  '.jpg',
  '.jpeg',
  '.png',
];

export const DEFAULT_REQUEST_ERROR_MESSAGE = 'Falha ao se conectar ao servidor';
export const ERROR_INVALID_PATTERN = 'Valor fora do padrão desejado';
export const ERROR_REQUIRED = 'Preencha o campo';
export const ERROR_EMAIL = 'Valor inserido não é um E-mail';
export const ERROR_STRING_LENGTH =
  'Este campo deve conter no mínimo 3 caracteres';
  export const ERROR_PASSWORD_NOT_MATCH = 'As senhas devem corresponder';

export const ERROR_FILE_SIZE =
  'Tamanho de arquivos não suportado, máximo de 4MB';
export const ERROR_FILE_TYPE = `Formato de arquivo inválido, formatos permitidos .jpg, .jpeg, .png`;
export const ERROR_QUANTITY_ZERO = 'A Quantidade não pode ser igual a zero';
export const ERROR_CNPJ_ALREADY_EXISTS = 'Este CNPJ já está cadastrado';
export const ERROR_EMAIL_ALREADY_EXISTS = 'Este e-mail já está cadastrado';

export const ORIENTATION_VERTICAL = 'vertical';
export const ORIENTATION_HORIZONTAL = 'horizontal';

export const STEPPES_REGISTER = ['Endereço', 'Registro', 'Contato', 'Marca', 'Login'];

export const STEPPES_NEW_ORDER = ['Solicitado', 'Indeferido', 'Deferido parcialmente', 'Deferido', 'Em Operação', 'Finalizado'];

export const FILE_SIZE = 4 << 20;

export const COLUMNS_ORDER_LIST = ['Produto', 'Quantidade', 'Marca', 'Status'];
export const COLUMNS_ORDER_PRODUCT = ['Produto', 'Marca', 'Código EAN-13','Volume do Recipiente'];
export const COLUMNS_SYSTEM_LOGS = ['Rota','Usuário','Horário', 'Status'];

export const USER_TYPES = {
  CLIENT: 'client',
  INTERNAL: 'internal'
}

export const SEAL_HISTORY = {
  DELIVERY_GRAPHIC: 1,
  MISPLACEMENT_GRAPHIC: 2,
  USE_COMPANY: 3,
  MISPLACEMENT_COMPANY: 4,
  DESTROY_COMPANY: 5,
}
