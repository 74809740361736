import styled from 'styled-components';
import { styled as styledUI } from '@material-ui/core/styles';
import { Link as LinkRouter } from 'react-router-dom';
import {
  fontSizes, device, spacing
} from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  
  h2{
    margin-bottom: ${spacing.smaller};
  }

  p{
    width: 500px;
    text-align: justify;
    font-size: ${fontSizes.small}
  }

  form{
    margin-top: ${spacing.smaller};
    width: 500px;
  }


  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0px;
    margin-top: ${spacing.smaller};

    p{
    width: 90%;
    text-align: justify;
    font-size: ${fontSizes.small}
  }

  form{
    margin-top: ${spacing.smaller};
    width: 90%;
  }

  h2{
      text-align: center;
    }
  }
`;

export const Link = styledUI(LinkRouter)({
  textDecoration: 'unset',
  alignSelf: 'flex-end',
});

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
`;


