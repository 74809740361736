import styled from 'styled-components';
import ImgBackgroundLeft from '~/assets/bg-image-left.png';
import ImgBackgroundRight from '~/assets/bg-image-right.png';
import {
  colors, device, fontSizes, spacing
} from '~/style/global';

export const Main = styled.main`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacing.base};
`;

export const BackgroundImageRight = styled.div`
  width: 300px;
  height: 300px;
  background-color: blue;
  background: url(${ImgBackgroundRight});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  
  @media ${device.tablet} {
    display: none;
  }
`;

export const BackgroundImageLeft = styled.div`  
  width: 300px;
  height: 300px;
  background-color: blue;
  background: url(${ImgBackgroundLeft});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;

  @media ${device.tablet} {
    display: none;
  }
`;

export const SupportedBrowsers = styled.div`
  max-width: 515px;
  margin: calc(20% - 10px) auto auto;


  div{
    display: flex;
    margin-bottom: ${spacing.short};
    justify-content: center;
  }

  img + img{
    margin-left: ${spacing.short};
  }

  p:first-of-type {
    font-weight: 600;
    margin-bottom: ${spacing.short};
  }
  
  p {
    font-size: ${fontSizes.default};
    color: ${colors.white};
    text-align: center;
    margin: 0 auto;
    font-weight: 400;
  }
`;

export const FormComponent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 300px;
  min-height: 100vh;
  align-items: center;
  padding: 40px 0px;
  background: ${colors.white};

  @media ${device.tablet} {
    padding: 20px;
    max-width: 100vw;
  }

  img {
    width: fit-content;
    height: fit-content;

    @media ${device.mobileL} {
      margin-top: 20px;
    }
  }
`;

export const ContainerForm = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  color: ${colors.lightGrey};
  bottom : 0;
  margin-top: ${spacing.smaller};
  text-align: center;
`;
