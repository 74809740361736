import React from 'react';
import { Container } from './styles';

function Base({ children, ...props}) {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
}

export default Base;
