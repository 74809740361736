import React from 'react';
import Loading from '~/components/Loading';
import { colors } from '~/style/global';
import { Container } from './styles';

function Outlined({ children, ...props}) {
  const { disabled } = props; 
  return (
    <Container {...props}>
      {disabled? <Loading.Button color={colors.blue} />: children }   
    </Container>
  );
}

export default Outlined;
