import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '~/style/global';

export const Portal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  width: 600px;
  background: ${colors.white};
  border-radius: 10px;
  padding: ${spacing.smaller};
  position: fixed;

  h1{
    font-size: ${fontSizes.medium};
    color: ${colors.darkGrey};
  }

  p{
    color: ${colors.mediumGrey};
    text-align: center;
    margin: ${spacing.base} 0;
  }

  h2{
    color: ${colors.mediumGrey};
    font-weight: normal;
  }

  > span{
    color: ${colors.mediumBlue};
    font-weight: bold;
    font-size: ${fontSizes.medium};
    margin-bottom: ${spacing.base};
  }

  button{
    width: 200px;
  }

  @media ${device.mobileL} {
    overflow: scroll;
  }
`;


