import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import ImageDenuncia from '~/assets/denuncia.png';
import imgError from '~/assets/onerror.png';
import Modal from '~/pages/Query/Modal';
import history from '~/services/history';
import { showModal } from '~/store/modules/modal/actions';
import { formatDate, formatDay, formatNumberSeal } from '~/utils';
import {
  BackgroundImage,
  Container,
  ContainerProduct,
  ContainerWarning,
  DataContainer,
  Event,
  Information,
  LineContainer
} from './styles';

function Details() {
  const dispatch = useDispatch();
  const { seal } = useSelector((state) => state.seal);

  const [textInformation, setTextInformation] = useState('');
  const [subTextInformation, setSubTextInformation] = useState('');
  const [currentEvent, setCurrentEvent] = useState('');
  const [titleImg, setTitleImg] = useState('');
  const [srcImg, setSrcImg] = useState('');

  useEffect(() => {
    if (!seal?.company?.cnpj) return history.goBack();

    return () => {
      dispatch(showModal('close', 7));
    };
  }, []);

  useEffect(() => {
    setCurrentEvent(0)
  }, [seal]);


  const firstEvent = seal?.events?.slice(0,1).shift();
  const checkIfEventTypeToEqualDeliveryGhaphic = firstEvent?.type === 'UG';
  const checkIfShowAlert = (checkIfEventTypeToEqualDeliveryGhaphic);

  const getTypeEvent = useCallback(() => {
    if (checkIfEventTypeToEqualDeliveryGhaphic) {
      setTextInformation(
        <p>
          O selo encontra-se na situação
          {' '}
          <b>&quot;Entregue&quot;</b>
          {' '}
          pela
          gráfica.
        </p>
      );
      setSubTextInformation(
        <p>
          A declaração da utilização pela fonte (envasadora) é posterior à
          entrega, e deverá constar até o dia útil seguinte à data da saída do
          produto da fonte para comercialização.
        </p>
      );
      dispatch(showModal('open', 7));
    }
  }, [seal]);

  const handleModal = () => getTypeEvent();

  const handleOnclickImg = (title, event) => {
    const src = event.target.getAttribute('src');
    setSrcImg(src);
    setTitleImg(title);
    dispatch(showModal('open', 8));
  }

  const handleErrorLoadImg = (event) => {
    event.target.setAttribute("src",imgError);
  }

  return (
    <Container alert={checkIfShowAlert}>
      <h1 onClick={() => history.push('/consulta')}>
        <FaArrowLeft />
        Selo N°
        {' '}
        {formatNumberSeal(seal?.seal?.sealCode)}
      </h1>
      { seal?.events && seal?.events[currentEvent] && (
        <h2>
          <span>Situação do Selo Fiscal:</span>
          <span onClick={() => handleModal()}>
            {seal?.events[currentEvent]?.situation} 
            {checkIfShowAlert && (<FiInfo className="icon" /> )}
          </span>
        </h2>
      )}
      {seal.seal?.showMessage && (
        <ContainerWarning>
          O código informado sob a raspadinha não corresponde ao registrado para
          este selo. Confira se o código digitado confere realmente com o do
          selo físico em sua posse. Em caso afirmativo, poderá
          <a href="https://portal.fazenda.sp.gov.br/servicos/selofiscalagua">
            {' '}
            denunciar
          </a>
          {' '}
          a divergência de informação à Sefaz-SP
        </ContainerWarning>
      )}

      <LineContainer>
        <div className="containerSeal">
          <img src={seal?.seal?.image} alt="Selo imagem" />
          <h3 className="abs" id="volume">
            {seal?.seal?.description}
            {' '}
          </h3>
          <h2 className="abs" id="brand">
            {seal?.seal?.brand}
          </h2>
          <h2 className="abs" id="seal">
            {formatNumberSeal(seal?.seal?.sealCode)}
          </h2>
          <h4 className="abs" id="seal-left">
            {formatNumberSeal(seal?.seal?.sealCode)}
          </h4>
          <h2 className="abs" id="rap">
            {seal?.seal?.scrapingCode !== null ? seal?.seal?.scrapingCode : ''}
          </h2>
        </div>
        <DataContainer width="55%">
          <h2 className="title">Envasadora</h2>
          <div className="container">
            <Information>
              <p>CNPJ</p>
              <span>{seal?.company?.cnpj}</span>
            </Information>
            {seal?.company?.name && (
              <Information>
                <p>Razão Social</p>
                <span>{seal?.company?.name}</span>
              </Information>
            )}
            {seal?.company?.fantasyName && (
              <Information>
                <p>Nome Fantasia</p>
                <span>{seal?.company?.fantasyName}</span>
              </Information>
            )}
          </div>
        </DataContainer>
      </LineContainer>
      <LineContainer>
        <DataContainer width="100%">
          <h2 className="title">Produto</h2>
          <div className="container">
            <ContainerProduct>
              <Information>
                <p>Código EAN-13</p>
                <span>{seal?.product?.ean13Code}</span>
              </Information>
              <Information>
                <p>NCM</p>
                <span>{seal?.product?.ncmCode}</span>
              </Information>
              <Information>
                <p>Lote</p>
                <span>{seal?.order?.protocolNumber}</span>
              </Information>
            </ContainerProduct>
            <ContainerProduct>
              <Information>
                <p>Marca</p>
                <p className="span">{seal?.brand?.name}</p>
                <img src={seal?.brand?.logo} alt={seal?.brand?.name} onClick={event => handleOnclickImg('Marca', event)} onError={handleErrorLoadImg} />
              </Information>
              <Information>
                <p>Rótulo</p>
                <img src={seal?.product?.labelImage} alt={seal?.brand?.name} onClick={event => handleOnclickImg('Rótulo', event)} onError={handleErrorLoadImg} />
              </Information>
              <Information>
                <p>Recipiente</p>
                <img
                  src={seal?.product?.containerImage}
                  alt={seal?.brand?.name}
                  onClick={event => handleOnclickImg('Recipiente', event)}
                  onError={handleErrorLoadImg}
                />
              </Information>
            </ContainerProduct>
          </div>
        </DataContainer>
      </LineContainer>
      <LineContainer>
        <DataContainer width="30%">
          <h2 className="title">Denúncia</h2>
          <div className="container">
            <img
              className="img-call"
              src={ImageDenuncia}
              alt="imagem para denuncia do selo"
            />
            <span className="span-call">
              Detectou algo estranho neste selo,
              <a href="https://portal.fazenda.sp.gov.br/servicos/selofiscalagua">
                {' '}
                clique aqui
              </a>
              {' '}
              para ir a página de denúncia.
            </span>
          </div>
        </DataContainer>
        <DataContainer width="65%">
          <h2 className="title">Eventos</h2>
          <div className="container">
            <Event>
              {seal?.events?.length ? (
                seal?.events.map((item, index) => (
                  <div className="item" key={index}>
                    <Information className="list">
                      <p>Evento</p>
                      <span>{item?.situation}</span>
                    </Information>
                    <Information>
                      <p>Data do evento</p>
                      <span>{formatDay(item?.dateEvent)}</span>
                    </Information>
                    <Information>
                      <p>Informação</p>
                      <span>
                        {formatDate(item?.dateInformation)}
                      </span>
                    </Information>
                  </div>
                ))
              ) : (
                <h3>Não possui nenhum evento ainda!</h3>
                )}
            </Event>
          </div>
        </DataContainer>
      </LineContainer>

      <Modal.Situation text={textInformation} subText={subTextInformation} />
      <Modal.ZoomImg title={titleImg} img={srcImg} />
      <BackgroundImage />
    </Container>
  );
}

export default Details;
