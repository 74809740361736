import styled from 'styled-components';
import { colors, device } from '~/style/global';

export const TableContainer = styled.div`
  width: 100%;
  min-height: 500px;

  @media ${device.mobileL} {
    min-height: auto;
    width: 95%;
    margin: 0px auto;
  }
`;
export const TableHeader = styled.div`
  height: 50px;
  background: ${colors.mediumBlue};
  color: ${colors.white};
  border-radius: 5px 5px 0 0;
  font-weight: 500;
`;

export const TableBody = styled.div`
  min-height: 300px;
  border-width: 0 1px 0 1px;
  border-color: ${colors.silver};
  border-style: solid;

  @media ${device.mobileL} {
    min-height: 100px;
  }
`;

export const TableData = styled.div`
  display: grid;
  flex-direction: column;

  a,
  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    min-height: 50px;
    color: currentColor;
    text-align: center;
    border-width: 0 0 1px 0;
    border-color: ${colors.silver};
    color: ${colors.shipGray};
    border-style: solid;
    text-decoration: none;
    align-items: center;
    padding: 5px;

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @media ${device.mobileL} {
      div {
        display: none;
      }
      ${(props) => props.nthChild} {
        display: block;
      }
    }
  }

  a:hover {
    background-color: ${colors.gallery};
  }
`;

export const TableFooter = styled.div`
  display: flex;

  height: 50px;
  border-width: 0 1px 1px 1px;
  border-color: ${colors.silver};
  border-style: solid;
  border-radius: 0 0 5px 5px;
  justify-content: flex-end;
  padding: 0 20px;
  color: ${colors.shipGray};

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .flex-start {
    margin-right: auto;
  }

  @media ${device.mobileL} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  height: 50px;
  text-align: center;
  align-items: center;

  @media ${device.mobileL} {
    div {
      display: none;
    }

    ${(props) => props.nthChild} {
      display: block;
    }
  }
`;
