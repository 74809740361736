import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Container, Details} from './styles';
import { fetchCompanyByProtocol } from '~/store/modules/companies/actions';

function CadastralSituationDetails() {
  
  const dispatch = useDispatch();
  const { companyByProtocol, status } = useSelector(state => state.companies);

  useEffect(() => {
    dispatch(fetchCompanyByProtocol());
  }, []);

  const checkIfDataLoaded = (data) => {
    return status === 'ok' ? data : 'Carregando ...';
  }

  return(
    <Container>
      <h2>Situação Cadastral da Envasadora</h2>
      <h4 id="sefazObservation">{checkIfDataLoaded(companyByProtocol?.sefazObservation)}</h4>
      <Details>
        <h4>Detalhes</h4>
        <div>
          <div>
            <h4>Protocolo</h4>
            <span>{checkIfDataLoaded(companyByProtocol?.protocolNumber)}</span> 
            <h4>Situação cadastral</h4>
            <span>{checkIfDataLoaded(companyByProtocol?.situation)}</span>
          </div>
          <div className="div-line">
            <h4>Início da vigência</h4>
            <span>{checkIfDataLoaded(companyByProtocol?.initialValidity)}</span> 
            <h4>Fim da vigência</h4>
            <span>{checkIfDataLoaded(companyByProtocol?.finalValidity)}</span>
          </div>
        </div>
      </Details>
      <Details>
        <h4>Histórico</h4>
        {companyByProtocol?.companyEvent?.map((companyHistory, index) => (
          <div className="div-history" key={index}>
            <div>
              <h4>Situação cadastral</h4>
              <span>{checkIfDataLoaded(companyHistory?.name)}</span>
            </div>
            <div className="div-date">
              <h4>Data e hora</h4>
              <span>{checkIfDataLoaded(companyHistory?.date)}</span>
            </div>          
          </div>
        ))}
      </Details>
    </Container>
  );
}

export default CadastralSituationDetails;