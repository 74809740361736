import React, { memo } from 'react';
import { Error } from '~/components/Error';
import { Container, ContainerInputError, Label } from '~/components/Input/Base/styles';
import { SelectField } from './styles';


const Base = React.forwardRef(({ ...props }, ref) => {
  const { name, options, errors, label, disable, data,className } = props;

  const valueSelected =
    options &&
    options.find(
      ({ uf, name: optionName }) => data === uf || data === optionName
    );

  return (
    <Container className={className}>
      <Label htmlFor={name}>{label}</Label>

      <ContainerInputError>
        <SelectField
          id={name}
          name={name}
          errors={errors}
          ref={ref}
          disabled={disable}
          value={valueSelected && valueSelected.id}
          {...props}
        >
          <option value="0"> Selecione </option>
          {options &&
            options.map(
              ({ id, name: optionName, street, image, type, number }) => {
                return (
                  <option
                    data-image={image}
                    data-type={type}
                    value={id}
                    key={id}
                  >
                    {optionName || `${street  }, ${  number}`}
                  </option>
                );
              }
            )}
        </SelectField>

        {errors && <Error name={name} errors={errors} marginRight />}
      </ContainerInputError>
    </Container>
  );
});

export default memo(Base);