import React from "react";
import { CgClose } from 'react-icons/cg';
import { useDispatch } from "react-redux";
import Modal from '~/components/Modal';
import { showModal } from '~/store/modules/modal/actions';
import { Portal } from '../styles';

function Terms() {

  const dispatch = useDispatch();

  return(
    <Modal id={3}>
      <Portal style={{maxWidth: '600px'}}>
        
        <h1>Termos e Orientações</h1>
        <CgClose onClick={() => dispatch(showModal('close', 3))} />

        <p>É Obrigatório informar adequada destinação (utilização, inutilização por destruição ou inutilização por extravio) dos selos nos prazos previstos na Portaria CAT 85/2020: Até o dia útil subsequente à ocorrência do fato;</p>
        <p>É Obrigatório a prestação dos corretos valores totais de ICMS sobre produtos comercializados;</p>
        <p>A envasadora é responsável pelos casos de selos encontrados no mercado após o prazo autorizado não previamente declarados como extraviados ou utilizados.</p>

      </Portal>
    </Modal>
)}

export default Terms;