import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '~/style/global';
import { Form } from '../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  color: ${colors.darkGrey};

  h1{
    margin-bottom: ${spacing.base};
  }

  h2, h3{
    text-align: center;
  }
  
`;

export const FormBrand = styled(Form)`
  width: 500px;
  margin-top: ${spacing.smaller};

  span.add{
    font-weight: bold;
    color: ${colors.mediumBlue};
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }


  svg{
    font-size: ${fontSizes.medium};
    margin-left: auto;
    cursor: pointer;
    display: flex;
    color: ${colors.mediumGrey};
  }
  

  .container-button{
    margin-top: ${spacing.base};
  }

  span > p{
    color: ${colors.red};
   font-weight: 600;
   text-align: center;
   margin-top: ${spacing.short};
  }

  h3{
    text-align: center;
  }

  span > label{
    display: flex;
    justify-content: center;
    margin: ${spacing.base} 0;
  }

  hr{
    margin: ${spacing.base} 0;
  }

  .upload-icon {
    max-width: 100%;
    object-fit: contain;
    max-height: 130px;
    margin-left: ${spacing.shorter};
  }

  span > label {
    max-width: 230px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin: ${spacing.shorter} auto;

    img{
      max-width: 250px;
      margin: auto;
      position: absolute;
    }

    input {
      height: 140px;
      width: 400px;
      opacity: 0;
      position: relative;
      opacity: 0;
      cursor: pointer;
    }

    svg {
      fill: ${colors.silver};
      font-size: 4em;
    }
  }

  .border-none {
    border: none !important;
  }

  .image-product {
    visibility: hidden;
  }

  @media ${device.mobileL} {
    width: inherit;
  }
`;


