import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '~/components/Button';
import Input from "~/components/Input";
import { USER_TYPES } from '~/config/constants';
import history from '~/services/history';
import { authenticate } from '~/store/modules/auth/actions';
import { loginFormSchema } from '~/utils/validation';
import { Container } from './styles';

function Login() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(loginFormSchema)
  });

  const { signed, userType, status } = useSelector(state => state.auth);

  useEffect(() => {
    if (signed){
      switch (userType) {
        case USER_TYPES.INTERNAL:
          history.push('/sistema/solicitacoes');
          break;
        case USER_TYPES.CLIENT:
          history.push('/sistema/cadastro-sefaz/detalhes');
          break;
        default:
          break;
      }
    } 
  }, [signed]);

  const onSubmit = values => {
    dispatch(authenticate(values));
  };

  return (
    <Container>
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input.Base 
          label="E-mail"
          name="email"
          type="text"
          ref={register}
          errors={errors.email}
          maxLength={50}
        />
              
        <Input.Base 
          label="Senha"
          name="password"
          type="password"
          ref={register}
          errors={errors.password}
          maxLength={64}
        />
          
        <Button.Rounded type="submit" disabled={status === 'fetching'} loading>
          Entrar
        </Button.Rounded>   
      </form>
      {location.pathname === '/login' && (
        <>
          <p>
            Não possui um login?
          </p>
          <Button.Outlined onClick={() => history.push('/cadastro')}>
            Registrar-se
          </Button.Outlined>
        </>
      )}
      
    </Container>
  );
}

export default Login;
