import React from 'react';
import { Route } from 'react-router-dom';

function RouterWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  return (
    <>
      <Route
        {...rest}
        render={(props) => (
          <Layout {...props}>
            <Component {...props} page="/login" />
          </Layout>
        )}
      />
    </>
  );
}

export default RouterWrapper;
