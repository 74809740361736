import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '~/components/Button';
import StepperBar from '~/components/StepperBar';
import {
  ORIENTATION_HORIZONTAL,
  STEPPES_NEW_ORDER,
  USER_TYPES,
} from '~/config/constants';
import { showModal } from '~/store/modules/modal/actions';
import {
  cleanOrderDetails,
  fetchOrderDetails,
} from '~/store/modules/orders/actions';
import { fetchHistory } from '~/store/modules/seal/actions';
import { formatQuantity } from '~/utils';
import Modal from './Modal';
import {
  Container,
  ContainerButton,
  Details,
  Observation,
  StepperBox,
} from './styles';

function OrderDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [step, setStep] = useState();
  const [typeNotification, setTypeNotification] = useState('');

  const { orderDetails, status } = useSelector((state) => state.orders);
  const { userType } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchOrderDetails(id));
    dispatch(fetchHistory(id));

    return () => {
      dispatch(cleanOrderDetails());
      dispatch(showModal('close', 4));
      dispatch(showModal('close', 5));
      dispatch(showModal('close', 6));
    };
  }, []);

  useEffect(() => {
    const orderEvent = orderDetails?.situationCode;
    const situationCode = [10, 15, 18, 20, 30, 40];
    const stepIndex = situationCode.indexOf(orderEvent);
    setStep(stepIndex);
  }, [orderDetails]);

  const openModal = (modalId, type = '') => {
    dispatch(showModal('open', modalId));
    setTypeNotification(type);
  };

  const checkIfDataLoaded = (data) => {
    return status === 'ok' ? data : 'Carregando ...';
  };

  return (
    <Container>
      <h2>Acompanhamento</h2>
      <h4>
        Nº do pedido:
        {' '}
        {orderDetails?.protocolNumber}
      </h4>
      <StepperBox>
        <StepperBar
          steppes={STEPPES_NEW_ORDER}
          activeStep={step}
          orientation={ORIENTATION_HORIZONTAL}
          className="container-step"
        />
      </StepperBox>

      {status === 'fetching' && <CircularProgress size={40} />}

      {step === 1 && <h2 id="text-red">Solicitação Indeferida</h2>}
      <div className="div-details">
        {userType === USER_TYPES.CLIENT && (
          <Button.Rounded className="btn-term" onClick={() => openModal(3)}>
            Termos e Orientações
          </Button.Rounded>
        )}
        <Details>
          <h4>Detalhes da solicitação</h4>
          <div id="div-row">
            <div>
              <h4>Produto</h4>
              <span>{checkIfDataLoaded(orderDetails?.product?.name)}</span>
              <h4>Marca</h4>
              <span>{checkIfDataLoaded(orderDetails?.brand?.name)}</span>
              <h4>Número inicial</h4>
              <span>{checkIfDataLoaded(orderDetails?.initialNumber)}</span>
            </div>
            <div className="div-line">
              <h4>Selos aprovados</h4>
              <span>{formatQuantity(orderDetails?.acceptedQuantity)}</span>
              <h4>Selos solicitados</h4>
              <span>{formatQuantity(orderDetails?.orderedQuantity)}</span>
              <h4>Número final</h4>
              <span>{checkIfDataLoaded(orderDetails?.finalNumber)}</span>
            </div>
          </div>
        </Details>
        <Observation>
          <div>
            <h4>Observação SEFAZ</h4>
            <span>{checkIfDataLoaded(orderDetails?.observationSefaz)}</span>
          </div>
          <div>
            <h4>Estimativa atual de duração (EAD)</h4>
            {orderDetails?.indicatorEad && orderDetails?.indicatorEad !== ''
              ? `${formatQuantity(orderDetails?.indicatorEad)} dia(s)`
              : 'Não calculado.'}
          </div>
        </Observation>
        <Details>
          <h4>Selos</h4>
          <div id="div-row">
            <div>
              <h4>Quantidade utilizados</h4>
              <span>{formatQuantity(orderDetails?.usedQuantity)}</span>
              <h4>Quantidade de selos entregues</h4>
              <span>{formatQuantity(orderDetails?.deliveredQuantity)}</span>
            </div>
            <div className="div-line">
              <h4>Quantidade inutilizados</h4>
              <span>{formatQuantity(orderDetails?.unusedQuantity)}</span>
            </div>
          </div>
        </Details>
        {step > 2 && (
          <Details>
            <h4>Informações</h4>
            <ContainerButton>
              {userType === USER_TYPES.CLIENT && (
                <>
                  <Button.Rounded
                    onClick={() => openModal(4, 'filling-destruction')}
                  >
                    Informar destruição
                  </Button.Rounded>
                  <Button.Rounded onClick={() => openModal(4, 'filling-use')}>
                    Informar utilização
                  </Button.Rounded>
                  <Button.Rounded
                    onClick={() => openModal(4, 'filling-misplacement')}
                  >
                    Extravio de envasadora
                  </Button.Rounded>
                </>
              )}
              {userType === USER_TYPES.INTERNAL && (
                <>
                  <Button.Rounded onClick={() => openModal(5)}>
                    Entrega da gráfica
                  </Button.Rounded>
                  <Button.Rounded
                    onClick={() =>
                      openModal(
                        6,
                        typeNotification === 'graphic' ? '' : 'graphic'
                      )}
                  >
                    Extravio da gráfica
                  </Button.Rounded>
                </>
              )}
            </ContainerButton>
          </Details>
        )}
      </div>
      <Modal.CompanyNotification typeNotification={typeNotification} />
      <Modal.DeliveryGhaphic />
      <Modal.GraphicMisplacement typeNotification={typeNotification} />
      <Modal.Terms />
    </Container>
  );
}

export default OrderDetails;
