import styled from 'styled-components';
import { BackgroundImageLeft } from '~/layouts/AuthLayout/styles';
import { colors, device, fontSizes, spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  .title-company {
    margin-left: ${spacing.base};
  }

  .containerSeal {
    position: relative;
    width: 380px;
    flex-shrink: 1;

    .abs {
      position: absolute;
    }

    h4 {
      font-size: 12px;
      font-weight: bold;
    }

    #brand {
      left: 60px;
      font-size: 18px;
      bottom: 23px;
      font-weight: bold;
    }

    #volume {
      left: 60px;
      font-size: 12px;
      bottom: 46px;
      font-weight: bold;
    }

    #seal {
      font-size: 18px;
      bottom: 5px;
      left: 60px;
      font-weight: bold;
    }

    #rap {
      left: 205px;
      font-size: 15px;
      top: 93px;
      font-weight: bold;
    }

    #seal-left {
      right: -45px;
      font-size: 12px;
      top: 90px;
      transform: rotate(270deg);
    }
  }

  h1 {
    color: ${colors.mediumBlue};
    margin-bottom: ${spacing.base};
  }

  h1, h2 {
    font-size: ${fontSizes.medium};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  svg {
    cursor: pointer;
    margin-right: ${spacing.base};
    font-weight: bold;
  }

  h2 span{
    width: fit-content;
  }

  h2 span:first-of-type{
    color: ${colors.mediumGrey};
  }

  h2 span:last-of-type{
    color: ${colors.lightBlue};
    margin-left: ${spacing.shorter};
    cursor: ${props => props.alert ? 'pointer' : 'default'};
  }

  h2 svg.icon {
    position: relative;
    font-size: ${fontSizes.default};
    margin-left: ${spacing.shorter};
  }

  img {
    max-width: 100%;
    max-height: 200px;
    margin-top: ${spacing.short};
    object-fit: contain;
  }

  .span-call {
    text-align: center;
  }

  a,
  a:hover,
  a:link {
    color: ${colors.lightBlue};
    font-weight: bold;
  }

  @media ${device.mobileL} {
    max-width: 90vw;

    .containerSeal{
      width: 100%;
    }

    h1, h2 {
      text-align: center;
      font-size: ${fontSizes.medium};
      word-break: break-word;
    }

    h1 svg {
      margin-right: 0;
    }

    > h2{
      margin-bottom: ${spacing.base}
    }

    img {
      max-width: 90vw;
    }
  }
`;

export const LineContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 200px;
  height: auto;
  flex-wrap: wrap;
  margin-top: ${spacing.smaller};

  img{
    cursor: pointer;
  }

  @media ${device.mobileL} {
    flex-direction: column;
    margin-top: 0px;
  }
`;

export const DataContainer = styled.div`
  width: ${({ width }) => (width || '100%')};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 200px;

  .title {
    align-self: flex-start;
    font-size: ${fontSizes.default};
    color: ${colors.lightBlue};
    margin-bottom: ${spacing.shorter};
    height: 25px;
  }

  .container {
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15),
      2px 2px 4px rgba(0, 0, 0, 0.15);
    color: ${colors.darkGrey};
    width: 100%;
    min-height: calc(200px - 40px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin-top: 30px;
  }
`;

export const Information = styled.div`
  width: 100%;
  height: auto;
  margin-top: ${spacing.base};
  margin-bottom: ${spacing.shorter};
  /* flex-shrink: 1; */
  flex-grow: 1;
	flex-basis: auto;

  p {
    font-weight: bold;
  }

  .span {
    font-weight: normal;
  }
`;

export const BackgroundImage = styled(BackgroundImageLeft)`
  box-shadow: none !important;
`;

export const Event = styled.div`
  width: 100%;
  border-radius: 10px;
  max-height: 300px;
  overflow: scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px 5px;
    border-left: 1px solid ${colors.gallery};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px 5px;
    background: ${colors.mediumBlue};
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.silver};
  }

  h3{
    color: ${colors.lightGrey};
    width: 100%;
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerProduct = styled.div`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between; 
  display: grid;
  grid-template-columns: repeat(3, calc((100% - 180px) / 6));
  padding: 10px 30px;

  @media ${device.mobileL} {
    grid-template-columns: 100%;
    margin-top: 0px;
  }
`;

export const ContainerWarning = styled.span`
  color: ${colors.red};
  font-weight: 600;
  text-align: center;
  margin: ${spacing.short} 0;
  max-width: 800px;
  z-index: 1;

  a,
  a:hover,
  a:link {
    color: ${colors.lightBlue};
    font-weight: bold;
  }
`;
