import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { colors, fontSizes } from '~/style/global';

export const Container = styled(Button)`
  height: 50px;
  border-radius: 5px;
  border: 2px solid ${colors.mediumBlue} !important;
  font-size: ${fontSizes.small};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.mediumBlue} !important;
  font-weight: 600;
`;
