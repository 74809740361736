import styled from 'styled-components';
import { colors, device, spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  color: ${colors.darkGrey};

  h1{
    margin-bottom: ${spacing.base};
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  > div, button{
    width: 240px;
  } 

  .street {
    width: 340px;
  }

  .number {
    width: 140px;
  }

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;

    > div, button{
      width: 100%;
    }

    button:last-of-type{
      margin-top: ${spacing.base}
    }
  }
`;

export const InformBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spacing.short} 0 ${spacing.smaller}; 

  h3, p{
    text-align: center;
  }
  
  p{
    margin: ${spacing.short} 0;
  }

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;

    button:last-of-type{
      margin-top: ${spacing.base}
    }
  }
`;

export const StepperBox = styled.div`
  width: 700px;
  margin-bottom: ${spacing.smaller};

  .MuiStepper-root{
    padding: 0;
  }

  @media ${device.mobileL} {
    width: inherit;
  }
`;

export const Form = styled.form`
  width: 500px;

  > p{
    color: ${colors.red};
   font-weight: 600;
   text-align: center;
   margin-top: ${spacing.short};
  }

  @media ${device.mobileL} {
    width: inherit;
  }
`;


