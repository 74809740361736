import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import imgWaterSeal from '~/assets/selo-agua.png';
import Button from '~/components/Button';
import Input from '~/components/Input';
import history from '~/services/history';
import { fetchSeal } from '~/store/modules/seal/actions';
import { sealsNumberMask } from '~/utils/mask';
import { queryFormSchema } from '~/utils/validation';
import { ContainerWarning } from '../Details/styles';
import { BackgroundImage, Container } from './styles';

function Search() {
  const dispatch = useDispatch();

  const { status, errorSeal } = useSelector(state => state.seal);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(queryFormSchema)
  });

  const onSubmit = values => {
    dispatch(fetchSeal(values));
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <h1>Consulta Pública de Verificação da Adequada Procedência do Selo Fiscal</h1>
      <h3>Somos responsáveis pela emissão dos selos com a letra inicial “P”</h3>

      {errorSeal && (
        <ContainerWarning>
          O código informado não foi encontrado. Confira se o código digitado inicia com a letra &#10077;P&#10078; e
          se está correto com o do selo físico em sua posse. Em caso afirmativo, poderá
          <a href="https://portal.fazenda.sp.gov.br/servicos/selofiscalagua">
            {' '}
            denunciar
          </a>
          {' '}
          a divergência de informação à Sefaz-SP. Em caso negativo acesse a página do
          <a href="https://portal.fazenda.sp.gov.br/servicos/selofiscalagua">
            {' '}
            portal de selos
          </a>
          {' '}
          para dúvidas na consulta.
        </ContainerWarning>
      )}

      <div>
        <figure>
          <img className='img-seal' src={imgWaterSeal} alt="Selo d' água" />
          <figcaption>
            <b>Exemplo:</b>
            {' '}
            XYAA - 000.000.001
          </figcaption>
        </figure>
        <form>
          <p>O código do selo é composto por 4 letras e 9 números, para ajudar na sua visualização destacamos ela na imagem.</p>
          <Input.Base
            label="Código do selo"
            name="sealCode"
            ref={register}
            errors={errors.sealCode}
            maxLength={16}
            onChange={(event) => { event.target.value = sealsNumberMask(event) }}
          />
          <span>Composto de 4 letras e 9 números</span>

          <Input.Base
            label="Código da raspagem (opcional)"
            name="scrapingCode"
            ref={register}
            errors={errors.scrapingCode}
            maxLength={8}
            onChange={(event) => {
              event.target.value = event.target.value.toUpperCase();
              return event;
            }}
          />

          <Button.Rounded type="submit" disabled={status === 'fetching'} loading>
            Consultar
          </Button.Rounded>

          <Button.Base onClick={() => history.push('/')}>
            Retornar para a página inicial
          </Button.Base>

        </form>
      </div>
      <BackgroundImage />
    </Container>

  );
}

export default Search;