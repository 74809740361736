import styled from 'styled-components';
import { spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  margin: ${spacing.base} 0;

  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: ${spacing.short};
    margin-top: ${spacing.shorter};
  } 
`;
