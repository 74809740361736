import styled from 'styled-components';
import { colors, spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p{
    margin: ${spacing.base} 0;
    max-width: 300px;
    text-align: center;
    color: ${colors.mediumGrey};
  }

  button{
    width: 350px;
  }
`;
