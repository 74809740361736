import NewOrder from "./NewOrder";
import OrderList from "./OrderList";
import OrderDetails from "./OrderDetails";

export default {
  NewOrder,
  OrderList,
  OrderDetails
}

