
import React from "react";
import { FiInfo } from "react-icons/fi";
import { useDispatch } from 'react-redux';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { showModal } from '~/store/modules/modal/actions';
import { Portal } from './styled';


function Situation({ text, subText }) {

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(showModal('close',7))
  };

  return(
    <Modal id={7}>
      <Portal>
        <h1>
          <FiInfo className="icon" />
          {' '}
          Informações do selo
        </h1>

        { text }

        { subText } 

        <p>
          Em caso de divergência, poderá
          {' '}
          <a href="https://portal.fazenda.sp.gov.br/servicos/selofiscalagua">
            denunciar
          </a>
          {' '}
          a informação à Sefaz-SP
        </p>

        <Button.Rounded type="button" onClick={onClose}>
          Continuar
        </Button.Rounded>
      </Portal>
    </Modal>
  );
}

export default Situation;