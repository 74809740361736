import produce from "immer";

const INITIAL_STATE = { address: {}, cities:[], state:[]};

const addresses = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {

    switch (action.type) {
      case "@addresses/FETCH_ADDRESS":
        draft.address = action.payload;
        break;
      case "@addresses/FETCH_STATES":
        draft.states = action.payload;
        break;
      case "@addresses/FETCH_CITIES":
        draft.cities = action.payload;
        break;
      default:
        return state;
    }
  });
};

export default addresses;
