import {
  CircularProgress as circularProgress,
  styled as styledUI,
} from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '~/style/global';

export const Modal = styled.div`
  position: fixed;
  width: 100vw;
  z-index: 100;
  height: auto;
  min-height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.progress ? 'center' : 'flex-start')};
  background: ${(props) => (props.colorLight ? 'transparent' : 'rgba(0, 0, 0, 0.38)')};
`;

export const CircularProgress = styledUI(circularProgress)({
  color: colors.mediumBlue,
  fontSize: 50,
});
