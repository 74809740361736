
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, ContainerButton } from './styles';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { newOrderFormSchema } from '~/utils/validation';
import history from "~/services/history";

function CadastralSituationResearch() {

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(newOrderFormSchema)
  });
  
  const onSubmit = () => {
    return null;
  };

  return (
    <>
      <Container>
        <h2>Consulta da situação cadastral na Sefaz</h2>
        <p>
          Digite o número do protocolo para consultar a sua situação cadastral atual no SEFAZ-SP.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input.Base 
            label="Código do protocolo"
            name="cod"
            type="text"
            ref={register}
            errors={errors.quantity}
            maxLength={15}
          />
          <ContainerButton>
            <Button.Rounded onClick={() => history.push('/sistema/cadastro-sefaz/detalhes')}>
              Pesquisar
            </Button.Rounded>
          </ContainerButton>
        </form>
      </Container>
    </>
  );
}

export default CadastralSituationResearch;
