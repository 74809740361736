import { FormControl } from '@material-ui/core';
import styled from 'styled-components';
import { FlexBox } from '~/pages/Registration/styles';
import { colors, device, fontSizes, spacing } from '~/style/global';

export const ContainerRadio = styled(FormControl)`
  margin-bottom: ${spacing.base};
`;

export const Portal = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  background: ${colors.white};
  border-radius: 10px;
  padding: ${spacing.smaller};
  position: fixed;
  min-width: 500px;
  max-height: 90vh;
  overflow: scroll;
  position: relative;

  > svg{
    position: absolute;
    right: 0;
    margin-right: 10px;
    font-size: ${fontSizes.medium};
    color: ${colors.mediumGrey};
    cursor: pointer;
  }

  hr{
    width: 100%;
    border-top: 1px solid ${colors.lightGrey};
    margin: -10px 0 ${spacing.base};
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px 5px;
    border-left: 1px solid ${colors.gallery};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px 5px;
    background: ${colors.mediumBlue};
  }

  h1{
    font-size: ${fontSizes.medium};
    color: ${colors.darkGrey};
    text-align: center;
  }

  p{
    color: ${colors.mediumGrey};
    text-align: justify;
    margin: ${spacing.base} 0;
    max-width: 500px;
    text-align: center;
  }

  h2{
    color: ${colors.mediumGrey};
    font-weight: normal;
  }

  > span{
    color: ${colors.mediumBlue};
    font-weight: bold;
    font-size: ${fontSizes.medium};
    margin-bottom: ${spacing.base};
  }

  b{
    margin-bottom: ${spacing.shorter};
  }

  .new-seal{
    margin: -${spacing.base} 0 ${spacing.base};
    margin-left: auto;
  }

  button{
    width: 200px;
    margin-top: ${spacing.shorter};
  }

  @media ${device.mobileL} {
    min-width: 90vw;
    overflow: scroll;
  }
`;

export const ContainerInput = styled(FlexBox)`
  align-items: center; 

  > div{
    width: 500px;
  }

  svg{
    margin-left: ${spacing.short};
    cursor: pointer;
  }

  > div + div {
    margin-left: 20px;
  }

  @media ${device.mobileL} {
    flex-direction: row;
  }
`;

export const ContainerSequential = styled(ContainerInput)`
  > div{
    width: 240px;
  }
`;
