import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from './styles';
import imgBgBtnCertificate from '~/assets/bg-btn-certificate.png';
import history from "~/services/history";
import Button from "~/components/Button";
import { authenticateWithCertificate } from "~/store/modules/auth/actions";

function CertificateAccess() {
  const dispatch = useDispatch();  

  const { signed } = useSelector(state => state.auth);

  useEffect(() => {
    if (signed){
      history.push('/sistema/cadastro-sefaz/detalhes');
    } 
    
  }, [signed]);

  const onSubmit = event => {
    event.preventDefault();

    dispatch(authenticateWithCertificate());
  };

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <h1>Acesso via Certificado Digital</h1>

        <p>Se você já possui Certificado Digital, por favor, clique na imagem abaixo.</p>

        <input type="image" src={imgBgBtnCertificate} alt="certificado digital" />   

      </form>
      <p>
        Não possui um login?
      </p>
      <Button.Outlined onClick={() => history.push('/cadastro')}>
        Registrar-se
      </Button.Outlined>      
    </Container>
  );
}

export default CertificateAccess;
