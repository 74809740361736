import React, { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import { logout } from '~/store/modules/auth/actions';
import { getToken } from "~/utils";

const DashboardLayout = lazy(() => import("~/layouts/DashboardLayout"));

const PrivateRoute = ({ component: Component, rest, isPrivate }) => {

  const dispatch = useDispatch();

  const token = getToken();

  if (!token && isPrivate) {
    dispatch(logout());

    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <DashboardLayout {...props}>
          <Component {...props} page="login" />
        </DashboardLayout>
        )}
    />
)
}

export default PrivateRoute;
