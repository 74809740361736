import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '~/style/global';

export const Portal = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  background: ${colors.white};
  border-radius: 10px;
  padding: ${spacing.smaller};
  position: fixed;
  min-width: 500px;
  max-width: 600px;
  max-height: 500px;
  overflow: scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px 5px;
    border-left: 1px solid ${colors.gallery};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px 5px;
    background: ${colors.mediumBlue};
  }

  h1 {
    font-size: ${fontSizes.medium};
    color: ${colors.darkGrey};
    text-align: center;
    display: flex;
    align-items: center;
  }

  p {
    color: ${colors.mediumGrey};
    text-align: center;
    margin-top: ${spacing.base};
  }

  h2 {
    color: ${colors.mediumGrey};
    font-weight: normal;
  }

  button {
    margin-top: ${spacing.base};
    width: 200px;
  }

  a,
  a:hover,
  a:link {
    color: ${colors.lightBlue};
    font-weight: bold;
  }

  .icon {
    position: relative;
    font-size: ${fontSizes.medium};
    color: ${colors.mediumBlue};
    margin-right: 10px;
  }

  @media ${device.mobileL} {
    min-width: 90vw;
    overflow: scroll;
  }
`;
