import { LinearProgress } from "@material-ui/core";
import React, { Fragment, lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import Order from "~/pages/Order";
import Product from "~/pages/Product";
import Query from "~/pages/Query";
import Registration from "~/pages/Registration";
import SystemAccess from "~/pages/SystemAccess";
import CadastralSituation from "~/pages/CadastralSituation";
import PrivateRoute from "./PrivateRoute";
import Route from "./Route";


const AuthLayout = lazy(() => import("~/layouts/AuthLayout"));
const DefaultLayout = lazy(() => import("~/layouts/DefaultLayout"));
const EmptyLayout = lazy(() => import("~/layouts/EmptyLayout"));
const QueryLayout = lazy(() => import("~/layouts/QueryLayout"));
const DashboardLayout = lazy(() => import("~/layouts/DashboardLayout"));
const Logs = lazy(() => import("~/pages/Logs"));
const NotFound = lazy(() => import("~/pages/NotFound"));
const FillingDetails = lazy(() => import("~/pages/FillingDetails"));

function Routes() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <Route path="/" exact component={SystemAccess.Browsers} layout={DefaultLayout} />
        <Route path="/login" exact component={SystemAccess.Login} layout={AuthLayout} />
        <Route path="/login/certificado" exact component={SystemAccess.CertificateAccess} layout={AuthLayout} />
        <Route path="/login/grafica" exact component={SystemAccess.Login} layout={AuthLayout} />
        <Route path="/cadastro" exact component={Registration.Address} layout={EmptyLayout} />
        <Route path="/cadastro/registro" exact component={Registration.Register} layout={EmptyLayout} />
        <Route path="/cadastro/contato" exact component={Registration.Contact} layout={EmptyLayout} />
        <Route path="/cadastro/marca" exact component={Registration.Brand} layout={EmptyLayout} />
        <Route path="/cadastro/login" exact component={Registration.Login} layout={EmptyLayout} />
        <Route path="/consulta" exact component={Query.Search} layout={QueryLayout} />
        <Route path="/consulta/selo" exact component={Query.Details} layout={QueryLayout} />
        <Route path="/sistema/solicitacoes/detalhes/:id" exact component={Order.OrderDetails} layout={DashboardLayout} />
        <PrivateRoute path="/sistema/solicitacoes" exact component={Order.OrderList} isPrivate />
        <PrivateRoute path="/sistema/solicitacoes/nova" exact component={Order.NewOrder} isPrivate />
        <PrivateRoute path="/sistema/cadastro-sefaz" exact component={CadastralSituation.CadastralSituationResearch} isPrivate />
        <PrivateRoute path="/sistema/cadastro-sefaz/detalhes" exact component={CadastralSituation.CadastralSituationDetails} isPrivate />
        <PrivateRoute path="/sistema/envasadora/detalhes" exact component={FillingDetails} isPrivate />
        <PrivateRoute path="/sistema/produtos" exact component={Product.ProductList} isPrivate />
        <PrivateRoute path="/sistema/produtos/novo" exact component={Product.NewProduct} isPrivate />
        <PrivateRoute path="/sistema/logs" exact component={Logs} isPrivate />
        <Route component={NotFound} layout={Fragment} />
      </Switch>
    </Suspense>
)}

export default Routes;
