import CompanyNotification from "./CompanyNotification";
import DeliveryGhaphic from "./DeliveryGhaphic";
import GraphicMisplacement from "./GraphicMisplacement";
import Terms from "./Terms";

export default{
  DeliveryGhaphic,
  CompanyNotification,
  GraphicMisplacement,
  Terms
}