import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '~/style/global';

export const Portal = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  background: ${colors.white};
  border-radius: 10px;
  padding: ${spacing.smaller};
  position: fixed;
  min-width: 600px;
  max-width: 80vw;
  max-height: 90vh;
  position: relative;

  > svg{
    position: absolute;
    right: 0;
    margin-right: 20px;
    margin-top: -15px;
    font-size: ${fontSizes.medium};
    color: ${colors.lightGrey};
    cursor: pointer;
  }

  h1{
    font-size: ${fontSizes.medium};
    color: ${colors.darkGrey};
    text-align: center;
    margin: ${spacing.base} 0;
  }

  @media ${device.mobileL} {
    min-width: 90vw;
    overflow: scroll;
  }
`;

export const Controllers = styled.div`
  display: flex;
  z-index: 500;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
   border: ${colors.lightGrey};
    color: ${colors.lightBlue};
    margin-right: 8px;
    font-weight: bold;
    font-size: ${fontSizes.default};

    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
`;

export const ImageContainer = styled.div`
  height: auto;
  width: auto;
  border-radius: 10px;
  overflow: hidden;

  .react-transform-component {
    width: 100% !important;
    cursor: move;
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
