
import styled from 'styled-components';
import { spacing, device, colors } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  h2{
    margin-bottom: ${spacing.short};
  }

  h4{
    margin-bottom: ${spacing.base};
    color: ${colors.mediumBlue};
  }

  @media ${device.mobileM} {
    margin-left: 0px;
    width: 100%;
    
    padding: ${spacing.base};

    h2{
      margin-top: ${spacing.smaller}; 
      text-align: center;
    }

    #sefazObservation{
      margin-top: ${spacing.base}; 
      text-align: center;
    }
   

  }
`;

export const StepperBox = styled.div`
  width: 700px;
  margin-bottom: ${spacing.smaller};

  .MuiStepper-root{
    padding: 0;
  }

  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const Details = styled.div`
  width: 70%;
  min-height: 50px;
  height: auto;
  border: 1px solid ${colors.silver};
  border-radius: 5px;
  padding: ${spacing.base};
  margin-bottom: ${spacing.base};

  .div-history{
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${colors.silver};

    div{
      width: 50%;
    }

    .div-date{
      padding-left: ${spacing.smaller}
    }

    h4{
      margin-top: ${spacing.short};
    }
  }

  h4{
    color: ${colors.mediumBlue};
  }

  div{
    min-height: 50px;
    height: auto;
    display: flex;
    flex-direction: row;

    .div-line{
      border-left: 1px solid ${colors.silver};
      padding-left: ${spacing.smaller}
    }

    div{
      width: 50%;
      display: flex;
      flex-direction: column;

      h4{
        color: ${colors.darkGrey};
        margin-bottom: 0px;
      }

      > span{
        margin-top: ${spacing.shorter};
        margin-bottom: ${spacing.base};
      }

    }
  }

  @media ${device.mobileL} {
    width: 100%;
    padding: ${spacing.base};

    h2{
      text-align: center;
    }
  }
`;

