import { toast } from 'react-toastify';
import { orders } from '~/services/api';
import history from '~/services/history';
import { requestError } from '~/utils/errors';

export const createOrder = (newOrder) => async dispatch => {
  dispatch({ type: '@orders/FETCHING_DATA' });
  try {
    await orders.insert(newOrder);
    toast.success('Solicitação criada com sucesso.');
    history.push('/sistema/solicitacoes');
  } catch (error) {
    requestError(error);

  } finally {
    dispatch({ type: '@orders/FETCHING_DATA_FINALLY' });
  }
};

export const fetchOrdersByCompany = (page) => async (dispatch) => {
  dispatch({ type: '@orders/FETCHING_DATA' });

  try {
    const { data, headers } = await orders.get(page);
    const total = headers['x-total-count'];
    dispatch({ type: '@orders/FETCH_ORDERS', payload: { data, total } });
  } catch (error) {
    requestError(error);
  } finally {
    dispatch({ type: '@orders/FETCHING_DATA_FINALLY' });
  }
};

export const fetchOrderDetails = (id) => async (dispatch) => {
  dispatch({ type: '@orders/FETCHING_DATA' });
  try {
    const { data } = await orders.getDetails(id);
    dispatch({ type: '@orders/FETCH_ORDER_DETAILS', payload: data.payload });
  } catch (error) {
    requestError(error);
  } finally {
    dispatch({ type: '@orders/FETCHING_DATA_FINALLY' });
  }
};

export const cleanOrderDetails = () => ({
  type: '@orders/CLEAN_ORDER_DETAILS',
});
