import { cities, states } from '~/services/api';
import { addresses } from '~/services/viacep';
import { requestError } from '~/utils/errors';
import { requestData } from '..';

const requestAddressesData = requestData('@addresses');

export const fetchAddress = (zipCode) => async dispatch => {
  try {
    const { data } = await addresses.select(zipCode);

    dispatch({ type: '@addresses/FETCH_ADDRESS', payload: data });
  } catch (error) {
    requestError(error);
  }
};

export const fetchStates = () => async dispatch => {
  requestAddressesData(
    dispatch,
    () => states.select(),
    payload => ({ type: '@addresses/FETCH_STATES', payload })
  );
};

export const fetchCities = stateId => async dispatch => {
  requestAddressesData(
    dispatch,
    () => cities.select(stateId),
    payload => ({ type: '@addresses/FETCH_CITIES', payload })
  );
};

