import styled from 'styled-components';
import { colors, spacing } from '~/style/global';
import imgBgBtnCertificate from '~/assets/bg-btn-certificate.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  color: ${colors.darkGrey};

  h1{
    margin-bottom: ${spacing.base};
  }

  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

 form > p{
   color: ${colors.red};
   font-weight: 600;
   text-align: center;
   margin-top: ${spacing.short};
 } 

 div.image{
    width: 300px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid ${colors.lightGrey};
    margin: ${spacing.base} 0;
    box-shadow: 1px 1px 5px 0 ${colors.lightGrey};
    background-image: url(${imgBgBtnCertificate});
    background-position: center;
    cursor:pointer;
  }

  b{
    color: ${colors.mediumGrey};
  }

`;

