import React from 'react';
import { CircularProgress, Modal } from './style';

export default function Progress({ colorLight }) {
  return (
    <Modal progress colorLight={!!colorLight}>
      <CircularProgress size={50} />
    </Modal>
  );
}
