import produce from "immer";

const INITIAL_STATE = { 
  orders: [],
  orderDetails: {},
  totalPages: 0,
  status: ''
};

const orders = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case "@orders/FETCH_ORDERS":
        draft.orders = action.payload;
        draft.totalPages = action.payload.total;
        break;
      case "@orders/FETCH_ORDER_DETAILS":
        draft.orderDetails = action.payload;
        break;
      case "@orders/FETCHING_DATA":
        draft.status = 'fetching';
        break;
      case "@orders/FETCHING_DATA_FINALLY":
        draft.status = 'ok';
        break;
      case "@orders/CLEAN_ORDER_DETAILS":
        draft.orderDetails = {};
        break;
      default:
        return state;
    }
  });
};

export default orders;
