import produce from "immer";

const INITIAL_STATE = { waterTypes: []};

const registries = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {

    switch (action.type) {
      case "@registries/FETCH_WATER_TYPES":
        draft.waterTypes = action.payload;
        break;
      default:
        return state;
    }
  });
};

export default registries;
