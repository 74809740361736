import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import history from '~/services/history';
import { clearFormData } from '~/store/modules/companies/actions';
import { showModal } from '~/store/modules/modal/actions';
import { Portal } from './styles';

const Success = () => {
  const dispatch = useDispatch();

  const { protocolNumber } = useSelector(state => state.companies);

  const handleClick = () => {
   dispatch(showModal('close', 2));
   dispatch(clearFormData());
   history.push('/');
  };

  return (
    <Modal id={2}>
      <Portal>
        <h1>Cadastro realizado com sucesso</h1>
        <p>Os dados foram transmitidos para a SEFAZ-SP para 1°análise da solicitação de cadastramento. Você poderá consultar a sua situação cadastral com o número do protocolo abaixo.</p>
        <h2>Número do protocolo</h2>
        <span>{protocolNumber}</span>
        <Button.Rounded onClick={handleClick}>
          Finalizar
        </Button.Rounded>
      </Portal>
    </Modal>
  );
};

export default Success;
