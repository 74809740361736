import React from 'react';
import { Link } from 'react-router-dom';
import Button from '~/components/Button';
import history from '~/services/history';
import { Container } from './styles';

function Browsers() {
  const handleRouteLogin = () => {
    const certificate = process.env.REACT_APP_LOGIN_WITH_CERTIFICATE;
    
    if(JSON.parse(certificate)){
      history.push('/login/certificado');
    }else{
      history.push('/login');
    }
  }

  return (
    <Container>
      <h1>Acessar o sistema</h1>
      <Button.Rounded onClick={handleRouteLogin}>
        Login
      </Button.Rounded>
      <hr />
      <Link to="/login/grafica">
        Login gráfica
      </Link>
      <p>
        Deseja verificar a autenticidade de um selo?
      </p>
      <Button.Outlined onClick={() => history.push('/consulta')}>
        CONSULTA DE SELOS
      </Button.Outlined>
    </Container>
  );
}

export default Browsers;
