import { toast } from 'react-toastify';
import { notifications } from '~/services/api';
import { requestError } from '~/utils/errors';
import { showModal } from '../modal/actions';

export const createGraphicDelivery = (graphicDelivery) => async dispatch => {
  dispatch({ type: '@notifications/FETCH_DATA'});
  try {
    await notifications.insertGraphicDelivery(graphicDelivery);

    dispatch(showModal('close', 5));

    toast.success('Operação registrada com sucesso');
    
    dispatch({ type: '@notifications/FETCH_DATA_FINALLY'});
  } catch (error) {
    dispatch({ type: '@notifications/FAILURE_FETCH_DATA' });
    
    requestError(error);
  }
};

export const createGraphicMisplacement = (graphicMisplacement) => async dispatch => {
  dispatch({ type: '@notifications/FETCH_DATA'});
  try {
    await notifications.insertGraphicMisplacement(graphicMisplacement);

    dispatch(showModal('close',6));

    toast.success('Operação registrada com sucesso');

    dispatch({ type: '@notifications/FETCH_DATA_FINALLY'});
  } catch (error) {
    dispatch({ type: '@notifications/FAILURE_FETCH_DATA' });

    requestError(error);
  }
};

export const createFillingUse = (graphicMisplacement) => async dispatch => {
  dispatch({ type: '@notifications/FETCH_DATA'});
  try {
    await notifications.insertFillingUse(graphicMisplacement);

    dispatch(showModal('close',4));

    toast.success('Operação registrada com sucesso');
    
    dispatch({ type: '@notifications/FETCH_DATA_FINALLY'});
  } catch (error) {
    dispatch({ type: '@notifications/FAILURE_FETCH_DATA' });
    
    requestError(error);
  }
};

export const createFillingMisplacement = (fillingMisplacement) => async dispatch => {
  dispatch({ type: '@notifications/FETCH_DATA'});
  try {
    await notifications.insertFillingMisplacement(fillingMisplacement);

    dispatch(showModal('close',4));

    toast.success('Operação registrada com sucesso');
    
    dispatch({ type: '@notifications/FETCH_DATA_FINALLY'});
  } catch (error) {
    dispatch({ type: '@notifications/FAILURE_FETCH_DATA' });
    
    requestError(error);
  }
};

export const createFillingDestruction = (fillingDestruction) => async dispatch => {
  dispatch({ type: '@notifications/FETCH_DATA'});
  try {
    await notifications.insertFillingDestruction(fillingDestruction);

    dispatch(showModal('close',4));

    toast.success('Operação registrada com sucesso');
    
    dispatch({ type: '@notifications/FETCH_DATA_FINALLY'});
  } catch (error) {
    dispatch({ type: '@notifications/FAILURE_FETCH_DATA' });
    
    requestError(error);
  }
};

