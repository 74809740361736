import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdInfoOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import Input from '~/components/Input';
import Progress from '~/components/Loading/Progress';
import Modal from '~/components/Modal';
import Select from '~/components/Select';
import { showModal } from '~/store/modules/modal/actions';
import { createOrder } from '~/store/modules/orders/actions';
import {
  cleanIndicatorEad,
  fetchProductCurrentEad,
  fetchProductsByCompany,
} from '~/store/modules/products/actions';
import { formatQuantity } from '~/utils';
import { numberDecimalMask } from '~/utils/mask';
import { newOrderFormSchema } from '~/utils/validation';
import { Container, ContainerButton, Portal } from './styles';

function OrderList() {
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(newOrderFormSchema),
  });

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [idProduct, setIdProduct] = useState('');
  const [ead, setEad] = useState('');
  const { productsByCompany, status, currentEad } = useSelector(
    (state) => state.products
  );
  const orderStatus = useSelector((state) => state.orders.status);

  useEffect(() => {
    dispatch(fetchProductsByCompany());
  }, []);

  useEffect(() => {
    dispatch(cleanIndicatorEad());
  }, []);

  useEffect(() => {
    if (idProduct !== '') {
      setEad(currentEad);
    }
  }, [currentEad]);

  useEffect(() => {
    setProducts(productsByCompany?.data?.payload);
  }, [productsByCompany]);

  const handleProducts = async (productId) => {
    setEad('');
    reset({ product: productId, quantity: '' });

    if (productId === '0') return dispatch(cleanIndicatorEad());

    dispatch(fetchProductCurrentEad(productId));
    setIdProduct(productId);
  };

  const handleClick = () => {
    dispatch(showModal('close', 3));
  };

  const openModal = () => {
    dispatch(showModal('open', 3));
  };

  const onSubmit = (data) => {
    const newOrder = {
      product: idProduct,
      quantity: data.quantity.replace(/[^\d]+/g, ''),
    };

    dispatch(createOrder(newOrder));
  };

  return (
    <>
      <Container>
        <h2>Solicitar lote de selo</h2>
        <p>
          A SEFAZ-SP utilizará, para critérios de deferimento, indicadores de
          mensuração da quantidade da utilização de selos, dentre eles a
          Estimativa Atual de Duração dos selos do produto em posse da
          envasadora (EAD)
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select.Base
            label="Produto"
            name="product"
            data={idProduct}
            type="text"
            defaultValue="Selecione"
            ref={register}
            errors={errors.product}
            options={products}
            onChange={(event) => handleProducts(event.target.value)}
          />
          <Input.Base
            label="Quantidade"
            name="quantity"
            type="text"
            ref={register}
            errors={errors.quantity}
            maxLength={9}
            onChange={(event) => {
              event.target.value = numberDecimalMask(event);
            }}
          />
          <div className="title-ead">
            <h4>
              EAD Atual:
              <span>
                {' '}
                {ead === ''
                  ? 'não calculado'
                  : `${formatQuantity(ead)} dia(s)`}{' '}
              </span>
            </h4>
            <MdInfoOutline onClick={openModal} />
          </div>
          {ead >= 60 && (
            <span>
              Quantidade de selos com EAD acima de sessenta dias, apresenta alta
              tendência de recusa nos critérios da SEFAZ SP
            </span>
          )}
          <ContainerButton>
            <Button.Rounded
              type="submit"
              disabled={orderStatus === 'fetching'}
              loading
            >
              Confirmar
            </Button.Rounded>
          </ContainerButton>
        </form>
        {status === 'fetching' && <Progress />}
      </Container>
      <Modal id={3}>
        <Portal>
          <h1>Como é calculado a Estimativa Atual de Duração</h1>
          <p>
            O EAD é calculado através da razão entre o número total de selos do
            produto entregues desta gráfica não destinados e a média histórica
            de consumo do produto (razão entre o número total de selos entregues
            destinados - soma de utilizados com inutilizados - e o número de
            dias decorridos desde a primeira entrega de lote de selos de
            qualquer gráfica à qual a envasadora esteve vinculada), desprezada a
            parte decimal.
          </p>
          <p>
            Caso o EAD estiver acima de sessenta dias, será apresentado como
            alta tendência de recusa nos critérios da SEFAZ-SP.
          </p>
          <p>
            Seu EAD será reduzida declarando adequadamente a destinação dos
            selos anteriormente entregues.
          </p>
          <Button.Rounded onClick={handleClick}>Ok</Button.Rounded>
        </Portal>
      </Modal>
    </>
  );
}

export default OrderList;
