import React from 'react';
import Loading from '~/components/Loading';
import { colors } from '~/style/global';
import { Container } from './styles';

function Rounded({ children, ...props}) {
  const { disabled, loading } = props; 
  return (
    <Container {...props}>
      {disabled && loading ? <Loading.Button color={colors.white} />: children }      
    </Container>
  );
}

export default Rounded;
