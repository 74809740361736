import { styled as styledUI } from '@material-ui/core/styles';
import { Link as LinkRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors, device, fontSizes, spacing
} from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  
  h2{
    margin-bottom: ${spacing.smaller};
  }

  p{
    width: 500px;
    text-align: justify;
    font-size: ${fontSizes.small}
  }

  form{
    margin-top: ${spacing.smaller};
    max-width: 500px;
    width: 100%;
  }

  form > span{
    color: ${colors.red};
    font-weight: 600;
    text-align: center;
    height: 500px;
  }

  .title-ead{
    display: flex;
    flex-direction: row;

    span{
      color: ${colors.green};
      font-weight: bold;
    }

    svg{
      margin-top: ${spacing.shorter};
      margin-left: ${spacing.shorter};
      cursor: pointer;
    }
  }

  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0px;
    margin-top: ${spacing.smaller};

    p{
    width: 90%;
    text-align: justify;
    font-size: ${fontSizes.small}
  }

  form{
    margin-top: ${spacing.smaller};
    width: 90%;
  }
  }
`;

export const Link = styledUI(LinkRouter)({
  textDecoration: 'unset',
  alignSelf: 'flex-end',
});

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;

  button{
    min-width: 150px;
  }
`;

export const Portal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  max-width: 700px;
  background: ${colors.white};
  border-radius: 10px;
  padding: ${spacing.smaller};
  position: fixed;

  h1{
    font-size: ${fontSizes.medium};
    color: ${colors.darkGrey};
    text-align: center;
    margin-bottom: ${spacing.base};
  }

  p{
    color: ${colors.mediumGrey};
    text-align: justify;
    margin-bottom: ${spacing.short};
  }

  h2{
    color: ${colors.mediumGrey};
    font-weight: normal;
  }

  > span{
    color: ${colors.mediumBlue};
    font-weight: bold;
    font-size: ${fontSizes.medium};
    margin-bottom: ${spacing.base};
  }

  button{
    width: 200px;
    margin-top: ${spacing.base};
  }

  @media ${device.mobileL} {
    overflow: scroll;
  }
`;





