import { toast } from 'react-toastify';
import { products } from '~/services/api';
import { requestError } from '~/utils/errors';
import history from '~/services/history';

export const createProduct = (data) => async (dispatch) => {
  dispatch({ type: '@products/FETCH_STATUS_CREATE' });
  try {
    await products.insertProductCompany(data);
    toast.success('Produto criado com sucesso.');
    history.push('/sistema/produtos');
  } catch (error) {
    requestError(error);
  } finally {
    dispatch({ type: '@products/FETCH_STATUS_CREATE_FINALLY' });
  }
};

export const fetchProductsByCompany = (page) => async (dispatch) => {
  dispatch({ type: '@products/FETCHING_DATA' });
  try {
    const { data, headers } = await products.selectProductsByCompany(page);
    const total = headers['x-total-count'];
    dispatch({
      type: '@company/FETCH_PRODUCTS_BY_COMPANY',
      payload: { data, total },
    });
  } catch (error) {
    requestError(error);
  } finally {
    dispatch({ type: '@products/FETCHING_DATA_FINALLY' });
  }
};

export const fetchProductCurrentEad = (productId) => async (dispatch) => {
  dispatch({ type: '@products/FETCHING_DATA' });
  try {
    const { data } = await products.selectCurrentEad(productId);

    dispatch({
      type: '@products/FETCH_PRODUCT_CURRENT_EAD',
      payload: data.payload,
    });
  } catch (error) {
    if (error.response.status !== 404) {
      requestError(error);
    }
  } finally {
    dispatch({ type: '@products/FETCHING_DATA_FINALLY' });
  }
};

export const cleanIndicatorEad = () => async (dispatch) =>
  dispatch({ type: '@products/CLEAN_INDICATOR_EAD' });
