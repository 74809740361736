import styled from 'styled-components';
import { colors, fontSizes, spacing } from '~/style/global';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: ${spacing.base};
`; 

export const Label = styled.label`
  width: 100%;
  margin-bottom: ${spacing.shorter};
  font-size: ${fontSizes.default};
  color: ${colors.darkGrey};
`;

export const TextField = styled.input`
  width: 100%;
  height: 40px;
  padding: ${spacing.short};
  box-sizing: border-box;
  border: none;
  background: ${colors.gallery};
  border-radius: 4px;
`;

export const ContainerInputError = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;

  .tooltip {
    background-color: #800505 !important;
    word-spacing: 0.1em !important;
    letter-spacing: 0.03em !important;
    width: 200px !important;
  }
`;

