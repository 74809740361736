import styled from 'styled-components';
import { styled as styledUI } from '@material-ui/core/styles';
import { Link as LinkRouter } from 'react-router-dom';
import {
   device, fontSizes, spacing, colors
} from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin-left: calc(10%);

  h2{
    margin-bottom: ${spacing.smaller};
  }

  #not-order{
    color: ${colors.scarpaFlow};
    margin-top: 130px;
    text-align: center;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0px;
    margin-top: ${spacing.smaller};
  }

`;

export const Link = styledUI(LinkRouter)({
  textDecoration: 'unset',
  alignSelf: 'flex-end',
});

export const Footer = styled.div`
  cursor: default;

  svg {
    cursor: pointer;
  }

  @media ${device.mobileM} {
    width: 100%;
    font-size: $${fontSizes.small};

    svg {
      font-size: ${fontSizes.medium};
    }
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;

  @media ${device.mobileL} {
    justify-content: center;
    margin-top: ${spacing.base};
    margin-bottom: ${spacing.base};
  }

`;