import Address from "./Address";
import Brand from "./Brand";
import Contact from "./Contact";
import Login from "./Login";
import Register from "./Register";

export default {
  Address,
  Register,
  Contact,
  Brand,
  Login
}

