import produce from 'immer';

const INITIAL_STATE = {
  productsByCompany: [],
  status: '',
  totalPages: 0,
  statusCreate: false,
  currentEad: '',
};

const products = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@company/FETCH_PRODUCTS_BY_COMPANY':
        draft.productsByCompany = action.payload;
        draft.totalPages = action.payload.total;
        break;
      case '@products/FETCHING_DATA':
        draft.status = 'fetching';
        break;
      case '@products/FETCH_STATUS_CREATE':
        draft.statusCreate = true;
        break;
      case '@products/FETCH_STATUS_CREATE_FINALLY':
        draft.statusCreate = false;
        break;
      case '@products/FETCHING_DATA_FINALLY':
        draft.status = 'ok';
        break;
      case '@products/FETCH_PRODUCT_CURRENT_EAD':
        draft.currentEad = action.payload.indicatorEad;
        break;
      case '@products/CLEAN_INDICATOR_EAD':
        draft.currentEad = '';
        break;
      default:
        return state;
    }
  });
};

export default products;
