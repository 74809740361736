import styled from 'styled-components';
import ImgBackgroundLeft from '~/assets/bg-image-left.png';
import { BackgroundImageRight } from '~/layouts/AuthLayout/styles';
import { colors, device, fontSizes, spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  h1,
  h3 {
    text-align: center;
  }

  h1 {
    font-size: ${fontSizes.medium};
    color: ${colors.mediumBlue};
  }

  h3 {
    color: ${colors.mediumGrey};
  }

  figcaption {
    text-align: center;
  }

  > div:first-of-type {
    display: flex;
    margin-top: ${spacing.smaller};
  }

  .img-seal {
    width: 100%;
    max-width: 400px;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin-left: ${spacing.smaller};
    align-items: center;
  }

  p {
    margin-bottom: ${spacing.smaller};
  }

  form > span {
    color: ${colors.lightGrey};
    margin: -${spacing.short} 0 ${spacing.base};
  }

  button:first-of-type {
    width: 100%;
    margin: ${spacing.short} 0;
  }

  @media ${device.mobileL} {
    h1 {
      font-size: ${fontSizes.medium};
    }

    > div:first-of-type {
      flex-direction: column;
    }

    figure,
    form,
    img {
      max-width: 90vw;
    }

    form {
      margin-left: 0;
      margin-top: ${spacing.base};
    }
  }
`;

export const BackgroundImage = styled(BackgroundImageRight)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const BackgroundImageLeft = styled.div`
  width: 300px;
  height: 300px;
  background-color: blue;
  background: url(${ImgBackgroundLeft});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;

  @media ${device.tablet} {
    display: none;
  }
`;
