import React, { forwardRef } from 'react';
import { Container } from './styles';

const Base = forwardRef(({children, ...props}, ref) => {
  const {name} = props;
  return (
    <Container {...props}>
      <input type="checkbox" id={name} name={name} ref={ref} value  />
      <label htmlFor={name}>
        {children}
      </label>
    </Container>
  )});

export default Base;