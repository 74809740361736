import styled from 'styled-components';
import { colors, fontSizes } from '~/style/global';

export const ErrorInfo = styled.div`
  color: ${colors.white};
  padding-top: 2px;
  font-size: ${fontSizes.small};
  word-break: break-word;
`;

export const InformationError = styled.div`
  position: absolute;
  right: ${props => (props.marginRight ? '15px' : '0px')};
  color: ${colors.darkRed};
  cursor: pointer;
  background: transparent;
  width: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  
  svg {
    font-size: ${fontSizes.medium};
    fill: ${colors.darkRed} !important;
  }
`;
