import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from '~/components/Button';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import { showModal } from "~/store/modules/modal/actions";
import { createGraphicDelivery } from "~/store/modules/notifications/actions";
import { formatDateToSefaz, getToday } from "~/utils";
import { informationSealFormSchema } from "~/utils/validation";
import { Portal } from '../styles';

function DeliveryGhaphic() {

  const [selectType, setSelectType] = useState('N');

  const { id } = useParams();
  const dispatch = useDispatch();

  const { status } = useSelector(state => state.notifications);

  const {handleSubmit, register, errors} = useForm({
    resolver: yupResolver(informationSealFormSchema),
  });

  const onSubmit = data => {
    const operationDate = formatDateToSefaz(data.operationDate);

    const json = {validate: selectType, order: id, operationDate};

    dispatch(createGraphicDelivery(json));
  };

  const handleChangeRadio = event => setSelectType(event.target.value);

  return(
    <Modal id={5}>
      <Portal onSubmit={handleSubmit(onSubmit)}>
        <CgClose onClick={() => dispatch(showModal('close',5))} />
        <h1>Informações do lote</h1>
        <p>A Entrega do lote será dividida em partes, o sistema enviará todos os selos do lote automaticamente.</p>
        <Input.Base 
          label="Data"
          name="operationDate"
          type="date"
          defaultValue={getToday()}
          ref={register}
          errors={errors.operationDate}
        />

        <h3>Modo de checagem</h3>
        <p>Os dados serão validados mas não terão os efeitos efetivamente atualizados na Fazenda em caso de adequação.</p>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue={selectType}
          >
           
            <FormControlLabel
              value='S'
              control={(
                <Radio
                  required
                  color="primary"
                />
                    )}
              label='Ativar'
              onChange={handleChangeRadio}
            />
            <FormControlLabel
              value='N'
              control={(
                <Radio
                  required
                  color="primary"
                />
                    )}
              label='Desativar'
              onChange={handleChangeRadio}
            />
          </RadioGroup>
        </FormControl>
                  
        <Button.Rounded type="submit" disabled={status === 'fetching'} loading>
          ENVIAR
        </Button.Rounded>
      </Portal>
    </Modal>
  );
}

export default DeliveryGhaphic;