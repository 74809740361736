import React, { forwardRef } from 'react';
import { Error } from '~/components/Error';
import { Container, ContainerInputError, Label, TextField } from './styles';

  
const Base = forwardRef((props, ref) => {
  const { errors, name, type, label, className } = props;

  return (
    <Container className={className}>
      <Label htmlFor={name}>{label}</Label>

      <ContainerInputError>
        <TextField
          id={name}
          type={type}
          name={name}
          errors={errors}
          ref={ref}
          {...props}
        />

        {errors && <Error name={name} errors={errors} />}
      </ContainerInputError>
    </Container>
  );
});

export default Base;