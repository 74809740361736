
import React, { useEffect, useState } from "react";
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import Loading from "~/components/Loading";
import Table from '~/components/Table';
import { COLUMNS_ORDER_LIST, USER_TYPES } from '~/config/constants';
import history from '~/services/history';
import { fetchOrdersByCompany } from '~/store/modules/orders/actions';
import { formatQuantity } from "~/utils";
import { Container, ContainerButton, Footer, Link } from './styles';

function OrderList() {

  const dispatch = useDispatch();
  
  const { orders, totalPages, status } = useSelector(state => state.orders);
  const { codeEvent, userType } = useSelector(state => state.auth);
  const [orderList, setOrderList] = useState([]);
  const [page, setPage] = useState(1);
  const totalPage = Math.ceil(totalPages / 10);

  const backPage = () => {
    setPage(page <= 1 ? 1 : page - 1);
  };

  const newPage = () => {
    setPage(page >= totalPage ? page : page + 1);
  };

  useEffect(() => {
    dispatch(fetchOrdersByCompany(page));
  }, [page]);

  useEffect(() => {
    setOrderList(orders?.data?.payload);
  }, [orders]);

  const pagination = (
    <Footer>
      <MdFirstPage onClick={() => setPage(1)} />
      <MdNavigateBefore onClick={backPage} />
      {page}
      {' '}
      de
      {' '}
      {totalPage}
      <MdNavigateNext onClick={newPage} />
      <MdLastPage onClick={() => setPage(totalPage)} />
    </Footer>
  );
 
  return (
    <Container>
      <h2>Solicitação de impressão</h2>
      {codeEvent !== 10 && codeEvent !== 15 && userType === USER_TYPES.CLIENT && (
        <ContainerButton>
          <Button.Rounded onClick={() => history.push('/sistema/solicitacoes/nova')}>
            Nova solicitação
          </Button.Rounded>
        </ContainerButton>
        )}
      <Table
        columns={COLUMNS_ORDER_LIST}
        width="4"
        nthChild="div:first-child,
        div:last-child"
        pagination={pagination}
        registrationTotal={totalPages}
      >
        {orderList?.length !== 0 ? (
          <> 
            {status === 'ok' ?
              (orderList?.map((item, index) => (
                <Link to={`/sistema/solicitacoes/detalhes/${item.id}`} key={index}>
                  <div>{item.productName}</div>
                  <div>{formatQuantity(item.quantity)}</div>
                  <div>{item.brandName}</div>
                  <div>{item.situation}</div>
                </Link>
              ))): 
              <Loading.Table />} 
          </>
        ) 
          : <h2 id="not-order">Você não possui solicitações! </h2> }
      </Table>
    </Container>
  );
}

export default OrderList;
