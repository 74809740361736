import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;

  #text-red{
    color: ${colors.red};
  }

  h2{
    color: ${colors.mediumBlue};
    margin-bottom: ${spacing.short};
  }

  h4{
    margin-bottom: ${spacing.base};
  }

  .btn-term{
    height: 35px;
    margin-left: auto;
    margin-bottom: ${spacing.base};
  }

  .div-details {
    width: 80%;
  }

  @media ${device.mobileL} {
    margin-left: 0px;
    width: 100%;
    padding: ${spacing.base};

    h2{
      margin-top: ${spacing.smaller}
    }

    .div-details {
      width: 100%;
    }
   
  }
`;

export const StepperBox = styled.div`
  width: 700px;
  margin-bottom: ${spacing.smaller};

  .MuiStepper-root{
    padding: 0;
  }

  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const Details = styled.div`
  width: 100%;
  min-height: 50px;
  height: auto;
  border: 1px solid ${colors.silver};
  border-radius: 5px;
  padding: ${spacing.base};
  margin-bottom: ${spacing.base};

  h4{
    color: ${colors.mediumBlue};
  }

  #div-row{
    min-height: 50px;
    height: auto;
    display: flex;
    flex-direction: row;

    .div-line{
      border-left: 1px solid ${colors.silver};
      padding-left: ${spacing.smaller}
    }

    div{
      width: 50%;
      display: flex;
      flex-direction: column;

      h4{
        color: ${colors.darkGrey};
        margin-bottom: 0px;
      }

      > span{
        margin-top: ${spacing.shorter};
        margin-bottom: ${spacing.base};
      }

    }
  }

  @media ${device.mobileL} {
    width: 100%;
    padding: ${spacing.base};
    
  }
`;

export const Observation = styled.div`
  width: 100%;
  min-height: 100px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.base};

  h4{
    color: ${colors.mediumBlue};
  }

  div{
    width: 49%;
    height: 150px;
    border: 1px solid ${colors.silver};
    border-radius: 5px;
    display: flex;
    padding: ${spacing.base};
    flex-direction: column;

    span{
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
      }
      overflow:auto; 
    }
  }

  @media ${device.mobileL} {
    width: 100%;

    div{
      height: 230px;
    }
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: space-between;
  color: ${colors.darkGrey};

  button + button{
    margin-left: ${spacing.short};
  }

  @media ${device.mobileL} {
      flex-direction: column;
      margin-bottom: 0px;

      button{
        margin-bottom: ${spacing.short};
        margin-left: 0;
      }
  }

  @media ${device.mobileS} {
      flex-direction: column;
  }
`;

export const ContainerRadio = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  color: ${colors.darkGrey};

  input[type=radio]{
    cursor: pointer;
    margin-right: 10px;
  }

  input[type=radio]:nth-of-type(2) {
    margin-left: ${spacing.base};
  }

  @media ${device.mobileL} {
      flex-direction: column;
      margin-bottom: 0px;
      
      button{
        margin-bottom: ${spacing.short}
      }
  }
`;


export const Portal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  width: 600px;
  background: ${colors.white};
  border-radius: 10px;
  padding: ${spacing.smaller};
  position: fixed;

  h1{
    font-size: ${fontSizes.medium};
    color: ${colors.darkGrey};
    text-align: center;
  }

  p{
    color: ${colors.mediumGrey};
    text-align: justify;
    margin: ${spacing.base} 0;
  }

  h2{
    color: ${colors.mediumGrey};
    font-weight: normal;
  }

  > span{
    color: ${colors.mediumBlue};
    font-weight: bold;
    font-size: ${fontSizes.medium};
    margin-bottom: ${spacing.base};
  }

  button{
    width: 200px;
  }

  @media ${device.mobileL} {
    overflow: scroll;
  }
`;
