import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CgClose } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '~/components/Button';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import { ContainerButton } from '~/pages/Order/NewOrder/styles';
import { showModal } from '~/store/modules/modal/actions';
import {
  createFillingDestruction,
  createFillingMisplacement,
  createFillingUse,
} from '~/store/modules/notifications/actions';
import { formatDateToSefaz, getToday } from '~/utils';
import { fillingUseFormSchema } from '~/utils/validation';
import { ContainerInput, ContainerSequential, Portal } from '../styles';

function CompanyNotification({ typeNotification }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [unitarySeals, setUnitarySeals] = useState([]);
  const [sequentialSeals, setSequentialSeals] = useState([]);
  const [selectType, setSelectType] = useState('N');
  const [currentEventSeal, setCurrentEventSeal] = useState('');

  const { status } = useSelector((state) => state.notifications);

  const initialStateInput = { sealCode: '' };

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: {
      unitarySeals: [],
    },
    resolver: yupResolver(fillingUseFormSchema),
  });

  useEffect(() => {
    setSequentialSeals([]);
    reset({ unitarySeals: [] });
  }, [typeNotification]);

  useEffect(() => {
    if (status === 'ok') {
      setUnitarySeals([]);
      setSequentialSeals([]);
    }
  }, [status]);

  const removeUnitary = (i) => {
    setUnitarySeals(unitarySeals.filter((value, index) => index !== i));
  };

  const removeSequential = (index) => {
    return setSequentialSeals(sequentialSeals.filter((_, i) => i !== index));
  };

  const removeEmptyBoxes = (boxesUnitarySeals, boxesSequentialSeals) => {
    boxesUnitarySeals.map(
      ({ sealCode }, index) => sealCode === '' && removeUnitary(index)
    );
    boxesSequentialSeals.map(
      ({ inferiorLimit, upperLimit }, index) =>
        inferiorLimit === '' && upperLimit === '' && removeSequential(index)
    );
  };

  const appendUnitary = () => {
    if (currentEventSeal === 'sequentialSeal')
      removeEmptyBoxes(unitarySeals, sequentialSeals);

    setCurrentEventSeal('unitarySeal');

    if (!unitarySeals.length) return setUnitarySeals([{ sealCode: '' }]);

    const emptyUnitarySeals = unitarySeals.filter(
      ({ sealCode }) => sealCode === ''
    );

    if (emptyUnitarySeals.length === 1) return null;

    return setUnitarySeals([...unitarySeals, { sealCode: '' }]);
  };

  const appendSequential = () => {
    if (currentEventSeal === 'unitarySeal')
      removeEmptyBoxes(unitarySeals, sequentialSeals);

    setCurrentEventSeal('sequentialSeal');

    if (!sequentialSeals.length)
      return setSequentialSeals([{ inferiorLimit: '', upperLimit: '' }]);

    const emptySequentialSeals = sequentialSeals.filter(
      ({ inferiorLimit, upperLimit }) =>
        inferiorLimit === '' && upperLimit === ''
    );

    if (emptySequentialSeals.length === 1) return null;

    return setSequentialSeals([
      ...sequentialSeals,
      { inferiorLimit: '', upperLimit: '' },
    ]);
  };

  const updateIndexSequentialSeals = (
    index,
    array,
    inferiorLimit,
    upperLimit
  ) =>
    setSequentialSeals([
      ...array.slice(0, index),
      { inferiorLimit, upperLimit },
      ...array.slice(index + 1),
    ]);

  const updateIndexUnitarySeals = (index, array, sealCode) =>
    setUnitarySeals([
      ...array.slice(0, index),
      { sealCode },
      ...array.slice(index + 1),
    ]);

  const onSubmit = (data) => {
    const operationDate = formatDateToSefaz(data.operationDate);

    const json = { ...data, validate: selectType, order: id, operationDate };

    switch (typeNotification) {
      case 'filling-use':
        dispatch(createFillingUse(json));
        break;
      case 'filling-misplacement':
        dispatch(createFillingMisplacement(json));
        break;
      case 'filling-destruction':
        dispatch(createFillingDestruction(json));
        break;
      default:
        break;
    }
  };

  const handleChangeRadio = (event) => setSelectType(event.target.value);

  const handleModal = (handle, idModal) => {
    setUnitarySeals([]);
    setSequentialSeals([]);

    return dispatch(showModal(handle, idModal));
  };

  return (
    <Modal id={4}>
      <Portal onSubmit={handleSubmit(onSubmit)}>
        <h1>Informações do lote</h1>
        <CgClose onClick={() => handleModal('close', 4)} />

        <Input.Base
          label="Data"
          name="operationDate"
          type="date"
          defaultValue={getToday()}
          ref={register}
          errors={errors.operationDate}
        />

        <h3>Modo de checagem</h3>
        <p>
          Os dados serão validados mas não terão os efeitos efetivamente
          atualizados na Fazenda em caso de adequação.
        </p>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue={selectType}
          >
            <FormControlLabel
              value="S"
              control={<Radio required color="primary" />}
              label="Ativar"
              onChange={handleChangeRadio}
            />
            <FormControlLabel
              value="N"
              control={<Radio required color="primary" />}
              label="Desativar"
              onChange={handleChangeRadio}
            />
          </RadioGroup>
        </FormControl>
        <p>É possível informar mais de uma sequência e número unitário</p>

        <ContainerButton>
          <Button.Base
            className="new-seal"
            onClick={() => appendUnitary(initialStateInput)}
          >
            adicionar selo +
          </Button.Base>
          <Button.Base className="new-seal" onClick={() => appendSequential()}>
            adicionar sequência +
          </Button.Base>
        </ContainerButton>

        {unitarySeals.map((_, index) => (
          <ContainerInput key={index}>
            <Input.Base
              label="Informar número do selo"
              name={`unitarySeals[${index}].sealCode`}
              type="text"
              ref={register}
              maxLength={13}
              errors={
                errors.unitarySeals &&
                errors.unitarySeals[index] &&
                errors.unitarySeals[index]?.sealCode
              }
              onChange={(event) => {
                return updateIndexUnitarySeals(
                  index,
                  unitarySeals,
                  event.target.value
                );
              }}
              value={unitarySeals[index].sealCode}
            />
            <CgClose onClick={() => removeUnitary(index)} />
          </ContainerInput>
        ))}

        {sequentialSeals.map((item, index) => (
          <ContainerSequential key={index}>
            <Input.Base
              label="Número inicial"
              name={`sequentialSeals[${index}].inferiorLimit`}
              type="text"
              maxLength={15}
              ref={register}
              errors={
                errors.sequentialSeals &&
                errors.sequentialSeals[index] &&
                errors.sequentialSeals[index]?.inferiorLimit
              }
              onChange={(event) => {
                return updateIndexSequentialSeals(
                  index,
                  sequentialSeals,
                  event.target.value,
                  sequentialSeals[index].upperLimit
                );
              }}
              value={sequentialSeals[index].inferiorLimit}
            />
            <Input.Base
              label="Número final"
              name={`sequentialSeals[${index}].upperLimit`}
              type="text"
              maxLength={15}
              ref={register}
              errors={
                errors.sequentialSeals &&
                errors.sequentialSeals[index] &&
                errors.sequentialSeals[index]?.upperLimit
              }
              onChange={(event) => {
                return updateIndexSequentialSeals(
                  index,
                  sequentialSeals,
                  sequentialSeals[index].inferiorLimit,
                  event.target.value
                );
              }}
              value={sequentialSeals[index].upperLimit}
            />
            <CgClose onClick={() => removeSequential(index)} />
          </ContainerSequential>
        ))}

        {(unitarySeals.length > 0 || sequentialSeals.length > 0) && (
          <Button.Rounded
            type="submit"
            disabled={status === 'fetching'}
            loading="true"
          >
            ENVIAR
          </Button.Rounded>
        )}
      </Portal>
    </Modal>
  );
}

export default CompanyNotification;
