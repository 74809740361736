import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;

  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1{
    font-size: ${fontSizes.medium};
    color: ${colors.mediumBlue};
    text-align: center;
  }

  p{
    color: ${colors.mediumGrey};
    margin: ${spacing.base} 0;
    text-align: center;
  }

  input{
    width: 300px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid ${colors.lightGrey};
    box-shadow: 1px 1px 5px 0 ${colors.lightGrey};
  }

  button{
    width: 350px;
  }

  @media ${device.mobileL} {
    min-width: 90vw;
    overflow: scroll;

    input, button{
      width: 100%;
    }

    > svg{
      top: ${spacing.base};
    }
  }
`;