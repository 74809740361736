import styled from 'styled-components';
import { colors, fontSizes, spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1{
    color: ${colors.darkGrey};
    font-size: ${fontSizes.medium};
  }

  button:first-of-type{
    margin-top: ${spacing.smaller};    
  }

  button{
    width: 250px;
  }

  hr{
    width: 80%;
    border-top: 1px solid ${colors.lightGrey};
    margin: ${spacing.smaller}
  }

  a:link, a:hover, a{
    color: ${colors.lightBlue};
    font-weight: bold;
  }

  p{
    margin: ${spacing.smaller} 0 ${spacing.short};
    max-width: 300px;
    text-align: center;
    color: ${colors.mediumGrey};
  }
`;
