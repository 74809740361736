import { toast } from 'react-toastify';
import { DEFAULT_REQUEST_ERROR_MESSAGE, ERROR_INVALID_PATTERN } from "~/config/constants";

export const errorInvalidPattern = exemplo =>
  `${ERROR_INVALID_PATTERN}. Exemplo: "${exemplo}"`;

/**
 *
 * @param {Error} error
 */
export const requestError = ({response}) => {
  if (!response) {
    return toast.error(DEFAULT_REQUEST_ERROR_MESSAGE);
  }

  response.data && response.data.errors && response.data.errors.map(error => {
    return toast.error(error.message);
  });
};