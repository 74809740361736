import produce from "immer";

const INITIAL_STATE = { 
  status: ''
};

const notifications = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {        
      case "@notifications/FETCH_DATA":
        draft.status = 'fetching';
        break;
      case "@notifications/FETCH_DATA_FINALLY":
        draft.status = 'ok';
        break;
      case "@notifications/FAILURE_FETCH_DATA":
        draft.status = 'error';
        break;
      default:
        return state;
    }
  });
};

export default notifications;
