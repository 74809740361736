import { seal } from '~/services/api';
import history from '~/services/history';
import { requestError } from '~/utils/errors';
import { requestData } from '..';

const requestSealData = requestData('@seal');

export const fetchSeal = (params) => async dispatch => {

  dispatch({ type: '@seal/FETCH_DATA' });

  try {
    const { data } = await seal.select(params);
    
    dispatch({ type: '@seal/FETCH_SEAL', payload: data.payload });

    history.push('/consulta/selo');
  } catch (error) {
    if (error.response.status === 404) {
      dispatch({ type: '@seal/ERROR_SEAL'});
    }else{
      requestError(error);
    }
  } finally {
    dispatch({ type: '@seal/FETCH_DATA_FINALLY' });
  }
};

export const fetchHistory = (orderId) => async dispatch => {
  requestSealData(
    dispatch,
    () => seal.selectHistory(orderId),
    payload => ({ type: '@seal/FETCH_HISTORY', payload })
  );
};