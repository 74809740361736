
import React, { useEffect, useState } from "react";
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import Loading from "~/components/Loading";
import Table from '~/components/Table';
import { COLUMNS_ORDER_PRODUCT } from "~/config/constants";
import history from '~/services/history';
import { fetchProductsByCompany } from "~/store/modules/products/actions";
import { Container, ContainerButton, Footer, Link } from './styles';

function ProductList() {

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const { productsByCompany, totalPages, status } = useSelector(state => state.products);
  const { codeEvent } = useSelector(state => state.auth);
  const totalPage = Math.ceil( totalPages / 10);

  const backPage = () => {
    setPage(page <= 1 ? 1 : page - 1);
  };

  const newPage = () => {
    setPage(page >= totalPage ? page : page + 1);
  };

  useEffect(() => {
    dispatch(fetchProductsByCompany(page));
  }, [page]);

  useEffect(() => {
    setProducts(productsByCompany?.data?.payload);
  }, [productsByCompany])

  const pagination = (
    <Footer>
      <MdFirstPage onClick={() => setPage(1)} />
      <MdNavigateBefore onClick={backPage} />
      {page}
      {' '}
      de
      {' '}
      {totalPage}
      <MdNavigateNext onClick={newPage} />
      <MdLastPage onClick={() => setPage(totalPage)} />
    </Footer>
  );
 
  return (
    <Container>
      <h2>Listagem de produtos</h2>
      {codeEvent !== 10 && codeEvent !== 15 ? (
        <ContainerButton>
          <Button.Rounded onClick={() => history.push('/sistema/produtos/novo')}>
            Novo produto
          </Button.Rounded>
        </ContainerButton>
 ) : null }
      <Table
        columns={COLUMNS_ORDER_PRODUCT}
        width="4"
        nthChild="div:first-child,
        div:last-child"
        pagination={pagination}
        registrationTotal={totalPages}
      >
        {products?.length !== 0 ? (
          <>
            {status === 'ok' ? 
            (products?.map((item, index) => (
              <Link key={index} to="/sistema/produtos">
                <div>{item.name}</div>
                <div>{item.brand}</div>
                <div>{item.ean13Code}</div>
                <div>
                  {item.containerVolume}
                  {' '}
                  ml
                </div>
              </Link>
            )))
            : <Loading.Table />}
          </>
      ) : <h2 id="not-order">Você não possui produtos! </h2> }
      </Table>
    </Container>
  );
}

export default ProductList;
