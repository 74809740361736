import { combineReducers } from 'redux';
import addresses from './modules/addresses';
import auth from './modules/auth';
import companies from './modules/companies';
import contacts from './modules/contacts';
import logs from './modules/logs';
import modal from './modules/modal';
import notifications from './modules/notifications';
import orders from './modules/orders';
import registries from './modules/registries';
import users from './modules/users';
import seal from './modules/seal';
import products from './modules/products';
import certificates from './modules/certificates';

const rootReducer = combineReducers({
  modal,
  addresses,
  companies,
  contacts,
  registries,
  users,
  auth,
  orders,
  logs,
  notifications,
  seal,
  products,
  certificates
});

export default rootReducer;
