import styled from 'styled-components';
import { colors, spacing } from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  color: ${colors.darkGrey};

  h1{
    margin-bottom: ${spacing.base};
  }
`;




