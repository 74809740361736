import React from 'react';
import {
  TableBody, TableContainer, TableData, TableFooter, TableHeader, TableRow,
} from './styles';

function Table({
  columns, children, nthChild, pagination, registrationTotal,
}) {
  return (
    <TableContainer>
      <TableHeader>
        <TableRow nthChild={nthChild}>
          {columns.map((column) => (
            <div key={column}>{column}</div>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableData nthChild={nthChild}>{children}</TableData>
      </TableBody>
      <TableFooter>
        <div className="flex-start">
          Total de registros:
          {' '}
          {registrationTotal}
        </div>
        <div>{pagination}</div>
      </TableFooter>
    </TableContainer>
  );
}

export default Table;
