import produce from "immer";

const INITIAL_STATE = { 
  logs: [],
  totalPages:0,
  status: ''
};

const logs = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case "@logs/FETCH_LOGS":
        draft.logs = action.payload.logsList;
        draft.totalPages = action.payload.total;
        break;
      case "@logs/FETCH_DATA":
        draft.status = 'fetching';
        break;
      case "@logs/FETCH_DATA_FINALLY":
        draft.status = 'ok';
        break;
      default:
        return state;
    }
  });
};

export default logs;
