import { Step, StepLabel, Stepper } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

 function StepperBar({
  steppes,
  activeStep,
  orientation,
  ...props
}) {
  const alternativeLabel = "horizontal";

  const classes = useStyles();

  return (
    <Stepper
      alternativeLabel={orientation === alternativeLabel}
      activeStep={activeStep}
      orientation={orientation}
      {...props}
    >
      {steppes.map(label => (
        <Step key={label}>
          <StepLabel
            StepIconProps={{
                classes: {
                  completed: classes.icon
                }
              }}
          >
            {label}
          </StepLabel>
        </Step>
        ))}
    </Stepper>
  );
}

export default StepperBar;
