import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { darken } from 'polished';
import { colors, fontSizes } from '~/style/global';

export const Container = styled(Button)`
  height: 50px;
  background-color: ${colors.mediumBlue} !important;
  border-radius: 5px;
  border: none;
  font-size: ${fontSizes.small};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white} !important;

  :hover{
    background-color: ${darken(0.05, colors.mediumBlue)};
  }
`;
