import { certificates } from '~/services/api';
import { requestError } from '~/utils/errors';

export const fetchSerialNumberCertificate = () => async dispatch => {
  try {
    const { data } = await certificates.select();

    dispatch({ type: '@certificates/FETCH_SERIAL_NUMBER', payload: data.payload.serialNumber })
  } catch (error) {
    requestError(error);
  }
};