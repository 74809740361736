import React from "react";
import { MdInfo } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import { ErrorInfo, InformationError } from "./styles";

export const Error = ({ ...props }) => {
  const { errors, name, marginRight } = props;
  return (
    <>
      <InformationError marginRight={!!marginRight} data-event='click focus' data-tip="custom show" data-for={name}>
        <MdInfo />
      </InformationError>

      <ReactTooltip
        className="tooltip"
        id={name}
        globalEventOff='click'
        place="left"
        type="error"
        effect="solid"
      >
        <ErrorInfo>{errors && errors.message}</ErrorInfo>
      </ReactTooltip>
    </>
  );
};
