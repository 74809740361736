import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes';
import history from './services/history';
import { persistor, store } from './store';
import Global from './style/global';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Global />
          <ToastContainer
            style={{ zIndex: '999' }}
            position="bottom-right"
            autoClose={7000}
            className="toast"
          />
          <Routes />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
