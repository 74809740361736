import { contacts } from '~/services/api';
import { requestData } from '..';

const requestContactsData = requestData('@contacts');

export const fetchContactsTypes = () => async dispatch => {
  requestContactsData(
    dispatch,
    () => contacts.select(),
    payload => ({ type: '@contacts/FETCH_CONTACTS_TYPES', payload })
  );
};

