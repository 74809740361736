import { isBackspace } from '.';

export const cnpjMask = (event) =>
  !isBackspace(event)
    ? event.target.value
        .replace(/[^\d]/g, '')
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
    : event.target.value;

export const zipCodeMask = (event) =>
  !isBackspace(event)
    ? event.target.value.replace(/[^\d]/g, '').replace(/^(\d{5})(\d)/, '$1-$2')
    : event.target.value;

export const phoneMask = (event) =>
  !isBackspace(event)
    ? event.target.value
        .replace(/[^\d]/g, '')
        .replace(/^(.{0})/, '$1')
        .replace(/^(.{2})/, '($1)')
    : event.target.value;

export const cellphoneMask = (event) =>
  !isBackspace(event)
    ? event.target.value
        .replace(/[^\d]/g, '')
        .replace(/^(.{0})/, '$1')
        .replace(/^(.{2})/, '($1)')
    : event.target.value;

export const numberDecimalMask = (event) =>
  event.target.value
    .replace(/[^\d]/g, '')
    .replace(/(.{1})(.{12})$/, '$1.$2')
    .replace(/(.{1})(.{9})$/, '$1.$2')
    .replace(/(.{1})(.{6})$/, '$1.$2')
    .replace(/(.{1})(.{3})$/, '$1.$2');

export const sealsNumberMask = (event) =>
  !isBackspace(event)
    ? event.target.value.toUpperCase()
        .replace(/[^\da-zA-Z]/g, '')
        .replace(/(.{10})(.{1})/, '$1.$2')
        .replace(/(.{7})(.{1})/, '$1.$2')
        .replace(/(.{4})(.{1})/, '$1-$2')
    : event.target.value;
