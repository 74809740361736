import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CgClose } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '~/components/Button';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import { showModal } from '~/store/modules/modal/actions';
import { createGraphicMisplacement } from '~/store/modules/notifications/actions';
import { formatDateToSefaz, getToday } from '~/utils';
import { graphicMisplacementFormSchema } from '~/utils/validation';
import { ContainerSequential, Portal } from '../styles';

function GraphicMisplacement({ typeNotification }) {
  const initialStateInput = { sealCode: '', scrapingCode: '' };

  const [unitarySeals, setUnitarySeals] = useState([initialStateInput]);
  const [selectType, setSelectType] = useState('N');

  const { id } = useParams();
  const dispatch = useDispatch();

  const { status } = useSelector((state) => state.notifications);

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: {
      unitarySeals: [initialStateInput],
    },
    resolver: yupResolver(graphicMisplacementFormSchema),
  });

  useEffect(() => {
    reset({ unitarySeals: [initialStateInput] });
  }, [typeNotification]);

  useEffect(() => {
    if (status === 'ok') {
      setUnitarySeals([initialStateInput]);
    }
  }, [status]);

  const removeUnitary = (i) => {
    setUnitarySeals(unitarySeals.filter((value, index) => index !== i));
  };

  const appendUnitary = () => {
    const emptyUnitarySeals = unitarySeals.filter(
      ({ sealCode, scrapingCode }) => sealCode === '' && scrapingCode === ''
    );

    if (emptyUnitarySeals.length === 1) return null;

    return setUnitarySeals([...unitarySeals, initialStateInput]);
  };

  const updateIndexUnitarySeals = (index, array, sealCode, scrapingCode) =>
    setUnitarySeals([
      ...array.slice(0, index),
      { sealCode, scrapingCode },
      ...array.slice(index + 1),
    ]);

  const onSubmit = (data) => {
    const operationDate = formatDateToSefaz(data.operationDate);

    const json = { ...data, validate: selectType, order: id, operationDate };

    dispatch(createGraphicMisplacement(json));
  };

  const handleChangeRadio = (event) => setSelectType(event.target.value);

  const handleModal = (handle, idModal) => {
    setUnitarySeals([initialStateInput]);

    return dispatch(showModal(handle, idModal));
  };

  return (
    <Modal id={6}>
      <Portal onSubmit={handleSubmit(onSubmit)}>
        <CgClose onClick={() => handleModal('close', 6)} />
        <h1>Informações do lote</h1>

        <Input.Base
          label="Data"
          name="operationDate"
          type="date"
          defaultValue={getToday()}
          ref={register}
          errors={errors.operationDate}
        />

        <h3>Modo de checagem</h3>
        <p>
          Os dados serão validados mas não terão os efeitos efetivamente
          atualizados na Fazenda em caso de adequação.
        </p>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue={selectType}
          >
            <FormControlLabel
              value="S"
              control={<Radio required color="primary" />}
              label="Ativar"
              onChange={handleChangeRadio}
            />
            <FormControlLabel
              value="N"
              control={<Radio required color="primary" />}
              label="Desativar"
              onChange={handleChangeRadio}
            />
          </RadioGroup>
        </FormControl>
        {unitarySeals.map((_, index) => (
          <ContainerSequential key={index}>
            <Input.Base
              label="Informar número do selo"
              name={`unitarySeals[${index}].sealCode`}
              type="text"
              ref={register}
              maxLength={13}
              errors={
                errors.unitarySeals &&
                errors.unitarySeals[index] &&
                errors.unitarySeals[index]?.sealCode
              }
              onChange={(event) =>
                updateIndexUnitarySeals(
                  index,
                  unitarySeals,
                  event.target.value,
                  unitarySeals[index].scrapingCode
                )
              }
              value={unitarySeals[index].sealCode}
            />
            <Input.Base
              label="Código de raspagem"
              name={`unitarySeals[${index}].scrapingCode`}
              type="text"
              ref={register}
              maxLength={8}
              errors={
                errors.unitarySeals &&
                errors.unitarySeals[index] &&
                errors?.unitarySeals[index]?.scrapingCode
              }
              onChange={(event) =>
                updateIndexUnitarySeals(
                  index,
                  unitarySeals,
                  unitarySeals[index].sealCode,
                  event.target.value
                )
              }
              value={unitarySeals[index].scrapingCode}
            />
            {unitarySeals.length > 1 && (
              <CgClose onClick={() => removeUnitary(index)} />
            )}
          </ContainerSequential>
        ))}
        <Button.Base className="new-seal" onClick={() => appendUnitary()}>
          novo selo +
        </Button.Base>
        <Button.Rounded type="submit" disabled={status === 'fetching'} loading>
          ENVIAR
        </Button.Rounded>
      </Portal>
    </Modal>
  );
}

export default GraphicMisplacement;
