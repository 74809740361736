import produce from "immer";

const INITIAL_STATE = { registriesTypes: [], formData: {}, responseEmail: false};

const users = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {

    switch (action.type) {
      case "@users/FETCH_EMAIL":
        draft.responseEmail = action.payload
        break;
      default:
        return state;
    }
  });
};

export default users;
