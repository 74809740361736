import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Loading } from './styles';

function Button({color}) {

  const useStyles = makeStyles({
    circular: {
      color,
    },
  });

  const classes = useStyles();

  return (
    <Loading size={20} className={classes.circular} />
  );
}

export default Button;
