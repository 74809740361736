import styled from 'styled-components';
import { styled as styledUI } from '@material-ui/core/styles';
import { Link as LinkRouter } from 'react-router-dom';
import {
  colors, fontSizes, device, spacing
} from '~/style/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  
  h2{
    margin-bottom: ${spacing.smaller};
  }


  form{
    margin-top: ${spacing.smaller};
    width: 500px;

    textarea{
      width: 100%;
      height: 80px;
      resize: none;
      padding: ${spacing.short};
      box-sizing: border-box;
      border: none;
      background: ${colors.gallery};
      border-radius: 4px;
    }
  }


  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0px;
    margin-top: ${spacing.smaller};
   
    form{
      width: 100%;
      padding: ${spacing.base};

      textarea{
        height: 120px;
        font-size: ${fontSizes.default};
      }

    }

    p{
      width: 90%;
      text-align: justify;
      font-size: ${fontSizes.small}
    }

  }
`;

export const Link = styledUI(LinkRouter)({
  textDecoration: 'unset',
  alignSelf: 'flex-end',
});

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;

  button{
    min-width: 200px;
  }
`;

export const Label = styled.label`
  width: 100%;
  margin-bottom: ${spacing.shorter};
  font-size: ${fontSizes.default};
  color: ${colors.darkGrey};
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div:nth-of-type(1) {
    margin-right: 5px;
  }

  .image > h3 {
    font-weight: normal;
  }

  .image{
    width: 49%;
    text-align: center;
  }

  .upload-icon {
    max-width: 100%;
    object-fit: contain;
    max-height: 130px;
  }

  .image > label {
    max-width: 230px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    margin: ${spacing.shorter} auto;

    img{
      max-width: 100%;
      margin: auto;
      margin-left: 20%;
      position: absolute;
    }

    input {
      height: 140px;
      width: 400px;
      opacity: 0;
      position: relative;
      visibility: hidden;
    }

    svg {
      fill: ${colors.silver};
      font-size: 4em;
    }
  }

  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
      width: 100%;
    }
  }
`;
